<div class="modal-header">
  <h4 class="modal-title">
    {{ (adSpaceId ? 'Edit AdSpace' : 'Add AdSpace') }}
  </h4>
</div>

<div class="modal-body">
  <form [formGroup]="adSpaceForm" (ngSubmit)="onSubmit()">
    <div class="form-group">
      <label>
        Names
      </label>

      <input
        [formControl]="name"
        [ngClass]="{ 'is-invalid': submitted && adSpaceFormControls.name.errors }"
        type="text"
        class="form-control"
        placeholder="Name"
      />

      <div class="invalid-feedback" *ngIf="submitted || adSpaceFormControls.name.errors">
        <div *ngIf="adSpaceFormControls.name.errors?.required">
          Name Is Required
        </div>
      </div>
    </div>

    <div class="form-group">
      <label>
        Website URL
      </label>

      <input
        [formControl]="url"
        [ngClass]="{ 'is-invalid': submitted && adSpaceFormControls.url.errors }"
        type="text"
        class="form-control"
        placeholder="Website URL"
      />

      <div class="invalid-feedback" *ngIf="submitted || adSpaceFormControls.url.errors">
        <div *ngIf="adSpaceFormControls.url.errors?.required">
          URL Is Required
        </div>

        <div *ngIf="adSpaceFormControls.url.errors?.pattern">
          Invalid URL (should start with http:// or https://)
        </div>
      </div>
    </div>

    <div class="form-group">
      <label>
        Description
      </label>

      <input
        [formControl]="description"
        [ngClass]="{ 'is-invalid': submitted && adSpaceFormControls.description.errors }"
        type="text"
        class="form-control"
        placeholder="Description"
      />

      <div class="invalid-feedback" *ngIf="submitted || adSpaceFormControls.description.errors">
        <div *ngIf="adSpaceFormControls.description.errors?.required">
          Description Is Required
        </div>
      </div>
    </div>

    <div class="form-group">
      <label>
        Traffic
      </label>

      <ng-select
        [formControl]="traffic"
        [ngClass]="{ 'is-invalid': submitted && adSpaceFormControls.traffic.errors }"
        bindValue="id"
        bindLabel="name"
        [items]="trafficDropdownList"
        placeholder="Traffic"
        [multiple]="true"
        class="expanding-dropdown-panel"
      >
      </ng-select>

      <div class="invalid-feedback" *ngIf="submitted || adSpaceFormControls.traffic.errors">
        <div *ngIf="adSpaceFormControls.traffic.errors?.required">
          Traffic Is Required
        </div>
      </div>
    </div>

    <div class="form-group">
      <label>
        Vertical
      </label>

      <ng-select
        [formControl]="verticals"
        [ngClass]="{ 'is-invalid': submitted && adSpaceFormControls.verticals.errors }"
        bindValue="id"
        bindLabel="name"
        [items]="verticalDropdownList"
        placeholder="Vertical"
        [multiple]="true"
        class="expanding-dropdown-panel"
      >
      </ng-select>

      <div class="invalid-feedback" *ngIf="submitted || adSpaceFormControls.verticals.errors">
        <div *ngIf="adSpaceFormControls.verticals.errors?.required">
          Vertical Is Required
        </div>
      </div>
    </div>

    <div class="form-group">
      <label>
        Categories
      </label>

      <ng-select
        [formControl]="categories"
        [ngClass]="{ 'is-invalid': submitted && adSpaceFormControls.categories.errors }"
        bindValue="id"
        bindLabel="name"
        [items]="categoriesDropdownList"
        placeholder="Categories"
        [multiple]="true"
        class="expanding-dropdown-panel"
      >
      </ng-select>

      <div class="invalid-feedback" *ngIf="submitted || adSpaceFormControls.categories.errors">
        <div *ngIf="adSpaceFormControls.categories.errors?.required">
          Categories Is Required
        </div>
      </div>
    </div>

    <div class="form-group">
      <label>
        Reach
      </label>

      <ng-select
        [formControl]="reach"
        [ngClass]="{ 'is-invalid': submitted && adSpaceFormControls.reach.errors }"
        bindValue="code"
        bindLabel="name"
        [items]="reachDropdownList"
        placeholder="Reach"
        [multiple]="true"
        class="expanding-dropdown-panel"
      >
        <ng-template ng-header-tmp>
          <div class="custom-select-all">
            <button class="btn btn-secondary" (click)="onSelectAll()">Select All</button>
            <button class="btn btn-secondary" (click)="onClearAll()">Clear All</button>
          </div>
        </ng-template>
        <ng-template ng-label-tmp let-item="item">
          <img *ngIf="item.code !== 'ZZ'" [src]="assetService.getFlag(item.code)" class="flag" />
          <img *ngIf="item.code === 'ZZ'" src="assets/img/rest_of_world.png" class="flag" width="12px" />
          <label class="targeted-countries ml-2">{{ item.name }}</label>
        </ng-template>
        <ng-template ng-option-tmp let-item="item">
          <img *ngIf="item.code !== 'ZZ'" [src]="assetService.getFlag(item.code)" class="flag" />
          <img *ngIf="item.code === 'ZZ'" src="assets/img/rest_of_world.png" class="flag" width="12px" />
          <span class="targeted-countries ml-2">{{ item.name }}</span>
        </ng-template>
      </ng-select>

      <div class="invalid-feedback" *ngIf="submitted || adSpaceFormControls.reach.errors">
        <div *ngIf="adSpaceFormControls.reach.errors?.required">
          Reach Is Required
        </div>
      </div>
    </div>

    <div class="modal-footer text-right">
      <button type="button" class="btn btn-secondary mr-3" (click)="activeModal.close()">
        Cancel
      </button>

      <button class="btn btn-primary" type="submit">
        Save
      </button>
    </div>
  </form>
</div>
