import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { AccountService } from 'src/app/shared/services/account.service';
import { BreadcrumbService } from 'src/app/shared/services/breadcrumb.service';
import { CoreService } from 'src/app/shared/services/core.service';

import { ConfirmDialogComponent } from '../../shared/confirm-dialog/confirm-dialog.component';
import { AccountUploadsSettingsModalComponent } from '../account-uploads-settings-modal/account-uploads-settings-modal.component';
import { SettingSection } from '../settings.base';

@Component({
  selector: 'app-account-uploads-settings',
  templateUrl: './account-uploads-settings.component.html',
  styleUrls: ['./account-uploads-settings.component.scss'],
})
export class AccountUploadsSettingsComponent extends SettingSection implements OnInit {
  breadCrumbData = [
    {
      name: 'Settings',
      link: '/setting/account',
    },
    {
      name: 'Account Uploads',
      link: '/setting/account-uploads-settings',
    },
  ];

  readOnly = false;

  currentAccountType: string = null;
  currentAccountId: number = null;

  accountUploads: any[] = [];

  constructor(
    public coreService: CoreService,
    public breadcrumbService: BreadcrumbService,
    public accountService: AccountService,
    public modalService: NgbModal,
  ) {
    super();

    this.currentAccountId = this.coreService.getCurrentAccountId();
    this.currentAccountType = this.coreService.getCurrentAccountType();

    if (this.coreService.settingsLocation === 'Account-Admin') {
      this.currentAccountId = this.coreService.editUserAccountId;
      this.currentAccountType = this.coreService.editAccountType;
    }
  }

  async ngOnInit() {
    this.breadcrumbService.updateBreadcrumbsData(this.breadCrumbData);

    this.fetchAccountUploads();
  }

  async fetchAccountUploads() {
    this.accountUploads = (await this.accountService.getAccountUploads(this.currentAccountId)) as any[];
  }

  async upload() {
    const modal = this.modalService.open(AccountUploadsSettingsModalComponent, {
      centered: true,
    });

    modal.componentInstance.accountId = this.currentAccountId;

    const res = await modal.result.catch(() => null);

    if (res) {
      this.fetchAccountUploads();
    }
  }

  async delete(upload) {
    const modal = this.modalService.open(ConfirmDialogComponent, {
      centered: true,
    });

    modal.componentInstance.title = 'Confirmation Required';

    modal.componentInstance.content = 'Are you sure you want to delete ' + `"${upload.name}"?`;

    const res = await modal.result.catch(() => null);

    if (res) {
      await this.accountService.deleteAccountUpload(upload.id);
      await this.fetchAccountUploads();
    }
  }
}
