import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';

import { SettingSection } from 'src/app/modules/setting/settings.base';
import { IAdSpace } from 'src/app/shared/constants/adSpace';
import { notifications, NotificationSettings } from 'src/app/shared/constants/notifications';
import { AdSpaceService } from 'src/app/shared/services/ad-space.service';
import { BreadcrumbService } from 'src/app/shared/services/breadcrumb.service';
import { CoreService } from 'src/app/shared/services/core.service';
import { IndicatorService } from 'src/app/shared/services/indicator.service';
import { TitleService } from 'src/app/shared/services/title-service';
import { UserManagementService } from 'src/app/shared/services/user-management.service';

@Component({
  selector: 'app-notification-settings',
  templateUrl: './notification-settings.component.html',
  styleUrls: ['./notification-settings.component.scss'],
})
export class NotificationSettingsComponent
  extends SettingSection implements OnInit {

  breadCrumbData = [
    { name: 'Settings', link: '/setting/account' },
    { name: 'Notification Settings', link: '/setting/notification-settings' },
  ];

  public readOnly = false;

  private notificationSettings: NotificationSettings;

  readonly notifications = notifications;
  public notificationFormGroup: UntypedFormGroup = new UntypedFormGroup({});

  public adSpaces: IAdSpace[] = [];
  public adSpacesFormGroup: UntypedFormGroup = new UntypedFormGroup({});

  constructor(public breadcrumbService: BreadcrumbService,
    public coreService: CoreService,
    private titleService: TitleService,
    private userManagementService: UserManagementService,
    private indicatorService: IndicatorService,
    private adSpaceService: AdSpaceService,
  ) {
    super();
  }

  async ngOnInit() {
    this.titleService.setTitle('Setting - Notification Settings');
    this.breadcrumbService.updateBreadcrumbsData(
      this.breadCrumbData,
    );

    this.notificationSettings = await this.userManagementService.getUserNotificationSettings(
      this.coreService.settingsLocation === 'Account-Admin' ? this.coreService.editUserId : null,
    );

    const adSpacesParams = this.coreService.settingsLocation === 'Account-Admin' ?
      { account: this.coreService.editUserAccountId } : {};

    this.adSpaces = await this.adSpaceService.getAdSpaceList(adSpacesParams).toPromise() as IAdSpace[];

    this.notificationFormGroup = this.toNotificationFormGroup();
    this.notificationFormGroup.valueChanges.subscribe(this.onNotificationFormGroupChange);

    this.adSpacesFormGroup = this.toAdSpacesFormGroup();
    this.adSpacesFormGroup.valueChanges.subscribe(this.onAdSpacesFormGroupChange);
  }

  async onNotificationFormGroupChange() {
    await this.userManagementService.updateUserNotificationSettings(
      { ...this.notificationSettings, ...this.notificationFormGroup.value },
      this.coreService.settingsLocation === 'Account-Admin' ? this.coreService.editUserId : null,
    );
    this.indicatorService.success(null, 'Notification settings updated successfully!');
  }

  async onAdSpacesFormGroupChange() {
    const mutedAdSpaces = Object.entries(this.adSpacesFormGroup.value)
      .filter(([_, v]) => !v)
      .map(([k, _]) => Number(k));
    await this.userManagementService.updateUserNotificationSettings(
      { ...this.notificationSettings, muted_media_ids: mutedAdSpaces },
      this.coreService.settingsLocation === 'Account-Admin' ? this.coreService.editUserId : null,
    );
    this.indicatorService.success(null, 'Notification settings updated successfully!');
  }

  public toNotificationFormGroup() {
    const group: any = {};

    notifications.forEach((notificationOption) => {
      group[notificationOption.id] = new UntypedFormControl(
        this.notificationSettings[notificationOption.id] || false,
      );
    });

    return new UntypedFormGroup(group);
  }

  public toAdSpacesFormGroup() {
    const group: any = {};

    this.adSpaces.forEach((adSpace) => {
      group[adSpace.id] = new UntypedFormControl(
        !this.notificationSettings.muted_media_ids.includes(adSpace.id),
      );
    });

    return new UntypedFormGroup(group);
  }
}
