import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AccountUploadsSettingsComponent } from './account-uploads-settings/account-uploads-settings.component';
import { PasswordSettingsComponent } from './password-settings/password-settings.component';

import { AccountSettingsComponent } from 'src/app/modules/setting/account-settings/account-settings.component';
import { AdSpaceSettingsComponent } from 'src/app/modules/setting/ad-space-settings/ad-space-settings.component';
import { AdminSettingsComponent } from 'src/app/modules/setting/admin-settings/admin-settings.component';
import { FinanceSettingsComponent } from 'src/app/modules/setting/finance-settings/finance-settings.component';
import { NotificationSettingsComponent } from 'src/app/modules/setting/notification-settings/notification-settings.component';
import { SettingComponent } from 'src/app/modules/setting/setting/setting.component';
import { UserManagementSettingsComponent } from 'src/app/modules/setting/user-management-settings/user-management-settings.component';

const routes: Routes = [
  {
    path: '',
    component: SettingComponent,
    children: [
      {
        path: 'account',
        component: AccountSettingsComponent,
      },
      {
        path: 'ad-space',
        component: AdSpaceSettingsComponent,
      },
      {
        path: 'finance-settings',
        component: FinanceSettingsComponent,
      },
      {
        path: 'user-management',
        component: UserManagementSettingsComponent,
      },
      {
        path: 'notification-settings',
        component: NotificationSettingsComponent,
      },
      {
        path: 'password-settings',
        component: PasswordSettingsComponent,
      },
      {
        path: 'admin-settings',
        component: AdminSettingsComponent,
      },
      {
        path: 'account-uploads-settings',
        component: AccountUploadsSettingsComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class SettingRoutingModule {}
