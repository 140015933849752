import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

import {
  ConfirmDialogComponent,
} from 'src/app/modules/shared/confirm-dialog/confirm-dialog.component';
import {
  IUserRequest,
  userGroupOptions,
} from 'src/app/shared/constants/user-management';
import { AuthService } from 'src/app/shared/services/auth.service';
import { CoreService } from 'src/app/shared/services/core.service';
import { DataService } from 'src/app/shared/services/data.service';
import { IndicatorService } from 'src/app/shared/services/indicator.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { SettingsService } from 'src/app/shared/services/settings.service';
import { UserManagementService } from 'src/app/shared/services/user-management.service';

import lodash from 'lodash';
import { NgxPermissionsService } from 'ngx-permissions';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-user-management-settings-model',
  templateUrl: './user-management-settings-model.component.html',
  styleUrls: ['./user-management-settings-model.component.scss'],
})
export class UserManagementSettingsModelComponent implements OnInit, OnDestroy {
  submitted = false;
  private permissionsArr = [];
  private addUserSubscription: Subscription;
  private updateUserByIdSubscription: Subscription;
  private currentAccountType: string;
  private currentAccountId;

  public permissionsOptions = [];
  public isGroupSelected;

  public userId;
  public isPrimary: boolean;
  public canBePromoted: boolean;

  public selectStatus = [];
  public selectedCountryCode;

  public userManagementSettingForm: UntypedFormGroup;
  public countryDialCodeOptions;

  userGroupOptions = userGroupOptions;

  constructor(
    public activeModal: NgbActiveModal,
    private fb: UntypedFormBuilder,
    private permissionsService: NgxPermissionsService,
    private coreService: CoreService,
    private notificationService: NotificationService,
    private userManagementService: UserManagementService,
    private dataService: DataService,
    private settingService: SettingsService,
    private modalService: NgbModal,
    private indicatorService: IndicatorService,
    private authService: AuthService,
  ) {
    if (this.coreService.settingsLocation === 'Account-Admin') {
      this.currentAccountType = this.coreService.editAccountType;
      this.currentAccountId = this.coreService.editUserAccountId;
      this.permissionsArr.push(this.currentAccountType);
      this.permissionsService.loadPermissions(this.permissionsArr);
    } else {
      this.currentAccountType = this.coreService.getCurrentAccountType();
      this.currentAccountId = this.coreService.getCurrentAccountId();
    }

    this.permissionsArr.push(this.currentAccountType);
    this.permissionsService.loadPermissions(this.permissionsArr);

    this.assignDynamicOptions();
  }

  createForm() {
    this.userManagementSettingForm = this.fb.group({
      status: [false, Validators.required],
      email: ['', [Validators.required, Validators.email]],
      first_name: ['', Validators.required],
      last_name: ['', Validators.required],
      groups: [null, Validators.required],
      phone_country_code: [''],
      phone: [''],
      promote_to_account_owner: [false],
    });
  }

  ngOnInit(): void {
    this.currentAccountType = this.coreService.getCurrentAccountType();
    this.createForm();

    if (this.userId) {
      this.getUserById(this.userId);
    }

    this.countryDialCodeOptions = this.dataService.countryDialCodes();
  }

  ngOnDestroy(): void {
    this.unsubscribeObject(this.addUserSubscription);
    this.unsubscribeObject(this.updateUserByIdSubscription);
    this.unsubscribeObject(this.updateUserByIdSubscription);
  }

  async assignDynamicOptions() {
    this.selectStatus = await this.settingService
      .getUserManagementStatusOptions();
  }

  async onSubmit() {
    this.submitted = true;
    const data = this.formateData(this.userManagementSettingForm.value);

    if (!this.userManagementSettingForm.valid) {
      return;
    }

    if (data.promote_to_account_owner === true) {
      if (!await this.confirmPromoteToAccountOwner()) {
        return;
      }
    }

    if (this.userId) {
      this.updateUser(data, this.userId);
    } else {
      this.addUser(data);
    }
  }

  async confirmPromoteToAccountOwner() {
    const modal = this.modalService.open(ConfirmDialogComponent, {
      centered: true,
    });

    modal.componentInstance.title = (
      'Confirmation Required'
    );

    modal.componentInstance.content = (
      'Are you sure you want to Promote '
      + 'this user to Account Owner?'
    );

    return await modal.result.then(
      () => true,
      () => false,
    );
  }

  async resetPassword() {
    const modal = this.modalService.open(ConfirmDialogComponent, {
      centered: true,
    });

    modal.componentInstance.title = (
      'Confirmation Required'
    );

    modal.componentInstance.content = (
      'Are you sure you want to reset '
      + 'this user password?'
    );

    const performResetPassword = () =>
      this.authService.forgotPassword({
        email: this.userManagementSettingForm.value.email,
      }).then(
        () => this.notificationService.showSuccess('Password reset link sent successfully'),
      ).catch(
        () => this.notificationService.showError('Unable to send password reset link'),
      );

    await modal.result.then(performResetPassword);
  }

  userDataGroupControl(controlName) {
    return this.userManagementSettingForm.get(controlName);
  }

  formateData(object) {
    return {
      ...object,
      groups: [object.groups],
      account: this.currentAccountId,
    } as IUserRequest;
  }

  getUserById(id) {
    this.userManagementService.getUser(id).subscribe(
      (data: any) => {
        this.userManagementSettingForm.patchValue({
          ...data,
          groups: lodash.get(data.groups, 0, null),
        });
      },
      this.indicatorService.failureIndicator(),
    );
  }

  updateUser(data, id) {
    if (this.isPrimary) {
      // Delete the status field as it doesn't need to be updated
      // when the user is a primary user for the account.
      delete data.status;
      delete data.groups;
    }

    this.updateUserByIdSubscription = this.userManagementService
      .updateUser(id, data).subscribe(
        () => {
          this.activeModal.close();
          this.notificationService.showSuccess('User updated successfully');
        },
        this.indicatorService.failureIndicator(),
      );
  }

  addUser(data) {
    this.addUserSubscription = this.userManagementService
      .addUser(data).subscribe(
        () => {
          this.activeModal.close();
          this.notificationService.showSuccess('User Added Successfully');
        },
        (error: HttpErrorResponse) => {
          if (error.error?.email) {
            this.notificationService.showError(error.error?.email[0]);
            return;
          }

          this.notificationService.showError(error?.message);
        },
      );
  }

  unsubscribeObject(object: Subscription) {
    if (object) {
      object.unsubscribe();
    }
  }
}
