<div class="tab-content">
  <div class="tab-pane fade show active">
    <div class="card shadow-none border-0">
      <div class="card-header text-right">
        <button type="button" class="btn btn-primary" (click)="upload()">
          Upload file
        </button>
      </div>

      <div class="card-body p-0">
        <div class="table-responsive-md">
          <table class="table table-striped">
            <thead>
              <tr>
                <th>Name</th>
                <th>Description</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let upload of accountUploads">
                <td>{{ upload.name }}</td>
                <td>{{ upload.description }}</td>
                <td>
                  <a href="{{ upload.file }}" target="_blank" class="m-r-10">
                    <img src="assets/icons/download.svg" />
                  </a>
                  <a (click)="delete(upload)">
                    <img src="assets/icons/delete.svg" />
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
