import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { AccountStatus } from 'src/app/shared/constants/account-admin';
import { userStatusOptions } from 'src/app/shared/constants/user-management';
import { CoreService } from 'src/app/shared/services/core.service';
import { environment } from 'src/environments/environment';

import lodash from 'lodash';
import urlJoin from 'url-join';

@Injectable({
  providedIn: 'root',
})
export class SettingsService {
  private baseClientUrl = urlJoin(environment.API_URL, '/');

  constructor(private http: HttpClient,
    private coreService: CoreService) { }

  async getUserManagementStatusOptions() {
    const details = await this.coreService.getCurrentUserDetailsLazy();
    const status = lodash.get(details, 'status', null);

    const nonActiveStatuses = [
      AccountStatus.PENDING,
      AccountStatus.INACTIVE,
    ];

    if (nonActiveStatuses.includes(status)) {
      return userStatusOptions.filter((option) => {
        return option.id !== AccountStatus.ACTIVE;
      });
    }

    return userStatusOptions;
  }

  changePassword(body) {
    const url = urlJoin(this.baseClientUrl, 'rest-auth/password/change/');
    return this.http.post(url, body)
      .toPromise();
  }

  changeOtherUserPassword(body) {
    const url = urlJoin(this.baseClientUrl, 'rest-auth/password/change/');
    return this.http.post(url, body)
      .toPromise();
  }
}
