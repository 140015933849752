import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { IAdSpace } from 'src/app/shared/constants/adSpace';
import { UrlRegex } from 'src/app/shared/constants/url';
import { AdSpaceService } from 'src/app/shared/services/ad-space.service';
import { AssetService } from 'src/app/shared/services/asset.service';
import { CoreService } from 'src/app/shared/services/core.service';
import { DataService } from 'src/app/shared/services/data.service';
import { IndicatorService } from 'src/app/shared/services/indicator.service';
import { NotificationService } from 'src/app/shared/services/notification.service';

import lodash from 'lodash';
import { NgxFileDropEntry } from 'ngx-file-drop';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-ad-space-settings-model',
  templateUrl: './ad-space-settings-model.component.html',
  styleUrls: ['./ad-space-settings-model.component.scss'],
})
export class AdSpaceSettingsModelComponent implements OnInit {
  public currentAccountId;
  public adSpaceId: number;
  public submitted = false;
  public selectedItems = [];
  public files: NgxFileDropEntry[] = [];

  public verticalDropdownList = [];
  public categoriesDropdownList = [];
  public reachDropdownList = [];
  public trafficDropdownList = [];

  name = new UntypedFormControl('', [Validators.required]);
  url = new UntypedFormControl('', [Validators.required, Validators.pattern(UrlRegex)]);
  description = new UntypedFormControl('', [Validators.required]);
  traffic = new UntypedFormControl('', [Validators.required]);
  verticals = new UntypedFormControl('', [Validators.required]);
  categories = new UntypedFormControl('', [Validators.required]);
  reach = new UntypedFormControl('', [Validators.required]);
  logo = new UntypedFormControl('');

  adSpaceForm = new UntypedFormGroup({
    name: this.name,
    url: this.url,
    description: this.description,
    traffic: this.traffic,
    verticals: this.verticals,
    categories: this.categories,
    reach: this.reach,
    logo: this.logo,
  });

  constructor(
    public activeModal: NgbActiveModal,
    private adSpaceService: AdSpaceService,
    private coreService: CoreService,
    private indicatorService: IndicatorService,
    private notificationService: NotificationService,
    private dataService: DataService,
    public assetService: AssetService,
  ) {
    this.currentAccountId = this.coreService.settingsLocation === 'Account-Admin' ?
      this.coreService.editUserAccountId :
      this.coreService.getCurrentAccountId();
  }

  get adSpaceFormControls() {
    return this.adSpaceForm.controls;
  }

  ngOnInit(): void {
    this.reachDropdownList = this.dataService.countrySelectItems();
    this.getAdSpace(this.adSpaceId);
    this.getCategories();
    this.getVerticals();
    this.getTraffic();
  }

  onSubmit() {
    this.submitted = true;

    if (this.adSpaceForm.invalid) {
      return this.indicatorService.failed(
        null,
        'Some fields are incomplete or invalid',
      );
    }

    const data = this.formateData();
    const adSpaceID = this.adSpaceId;

    const observable = this.adSpaceId ?
      this.adSpaceService.updateAdSpace(adSpaceID, data) :
      this.adSpaceService.addAdSpace(data);

    observable.subscribe(
      () => {
        this.activeModal.close();
        this.coreService.showSuccess('AdSpace Saved Successfully');
      },
      (error: HttpErrorResponse) => {
        this.notificationService.showError(error?.message);
        this.coreService.showError('AdSpace Saving Failed');
      },
    );
  }

  formateData() {
    const data = new FormData();

    const valueProps = [
      'name',
      'url',
      'description',
      'traffic',
      'verticals',
      'categories',
      'reach',
    ];

    const fileProps = [
      'logo',
    ];

    valueProps.forEach((field) => {
      let value = this.adSpaceForm.get(field).value;

      if (!lodash.isString(value)) {
        value = JSON.stringify(value);
      }

      data.append(field, value);
    });

    fileProps.forEach((field) => {
      const file = this.adSpaceForm.get(field).value;

      if (file && file.file) {
        data.append(field, file.file, file.name);
      }
    });

    data.append(
      'account',
      this.currentAccountId,
    );

    return data;
  }

  getAdSpace(adSpaceId) {
    if (!adSpaceId) {
      return;
    }

    this.adSpaceService.getAdSpace(this.adSpaceId).subscribe(
      (adSpaceData: IAdSpace) => {
        this.adSpaceForm.patchValue({
          name: adSpaceData.name,
          url: adSpaceData.url,
          description: adSpaceData.description,
          traffic: adSpaceData.traffic,
          verticals: adSpaceData.verticals,
          categories: adSpaceData.categories,
          reach: adSpaceData.reach,
        });
      },
      (error: HttpErrorResponse) => {
        this.notificationService.showError(error?.message);
      },
    );
  }

  unsubscribeObject(object: Subscription) {
    if (object) {
      object.unsubscribe();
    }
  }

  public clear() {
    this.files = [];
    this.logo.reset();
  }

  public getFileSelectionName() {
    if (!this.isFileSelected()) {
      return null;
    }

    const file = this.files[0];
    return file.fileEntry.name;
  }

  public isFileSelected() {
    return (this.files && this.files.length >= 1);
  }

  getCategories() {
    this.adSpaceService.getAdspaceCategory().then((data: any) => {
      this.categoriesDropdownList = data;
    });
  }

  getVerticals() {
    this.adSpaceService.getAdspaceVertical().then((data: any) => {
      this.verticalDropdownList = data;
    });
  }

  getTraffic() {
    this.adSpaceService.getAdspaceTraffic().then((data: any) => {
      this.trafficDropdownList = data;
    });
  }

  onSelectAll() {
    const selected = this.reachDropdownList.map((item) => item.code);
    this.adSpaceForm.get('reach').patchValue(selected);
  }

  onClearAll() {
    this.adSpaceForm.get('reach').patchValue([]);
  }
}
