<!-- Dropdown for small screens -->
<div class="d-md-none mb-3">
  <select class="form-control" [(ngModel)]="activeSettingTab" (change)="navigateToSetting()">
    <ng-container *ngxPermissionsExcept="'Admin'">
      <option value="/setting/account">Account</option>
    </ng-container>
    <ng-container *ngxPermissionsOnly="'Publisher'">
      <option value="/setting/ad-space">AdSpace</option>
    </ng-container>
    <ng-container *ngxPermissionsExcept="'Admin'">
      <option value="/setting/finance-settings">Finance Settings</option>
    </ng-container>
    <option value="/setting/user-management">User Management</option>
    <option value="/setting/notification-settings">Notification Settings</option>
    <option value="/setting/password-settings">Password Settings</option>
    <ng-container *ngIf="isCurrentAccountAdmin">
      <option value="/setting/admin-settings">Admin Settings</option>
      <option value="/setting/account-uploads-settings">Account Uploads</option>
    </ng-container>
  </select>
</div>

<!-- Vertical tab navigation for larger screens -->
<div class="settings-menu vertical-tab d-none d-md-block">
  <div class="nav-item flex-column nav-pills border-0">
    <ng-container *ngxPermissionsExcept="'Admin'">
      <a class="nav-link" routerLink="/setting/account" [class.active]="componentType[1].name === 'Account'">
        Account
      </a>
    </ng-container>

    <ng-container *ngxPermissionsOnly="'Publisher'">
      <a class="nav-link" routerLink="/setting/ad-space" [class.active]="componentType[1].name === 'AdSpace'">
        AdSpace
      </a>
    </ng-container>

    <ng-container *ngxPermissionsExcept="'Admin'">
      <a class="nav-link" routerLink="/setting/finance-settings" [class.active]="componentType[1].name === 'Finance Settings'">
        Finance Settings
      </a>
    </ng-container>

    <a class="nav-link" routerLink="/setting/user-management" [class.active]="componentType[1].name === 'User Management'">
      User Management
    </a>

    <a class="nav-link custom-bottom-line" routerLink="/setting/notification-settings" [class.active]="componentType[1].name === 'Notification Settings'">
      Notification Settings
    </a>

    <a class="nav-link" routerLink="/setting/password-settings" [class.active]="componentType[1].name === 'Password Settings'">
      Password Settings
    </a>

    <ng-container *ngIf="isCurrentAccountAdmin">
      <a class="nav-link" routerLink="/setting/admin-settings" [class.active]="componentType[1].name === 'Admin Settings'">
        Admin Settings
      </a>

      <a class="nav-link" routerLink="/setting/account-uploads-settings" [class.active]="componentType[1].name === 'Account Uploads'">
        Account Uploads
      </a>
    </ng-container>
  </div>
</div>
