<div class="tab-content">
  <div class="tab-pane fade show active">
    <form [formGroup]="passwordForm" (ngSubmit)="onSubmit()">
      <div class="card shadow-none">
        <div class="card-body p-0">
          <div class="row">
            <div class="col-lg-12">
              <h3 class="title">Change Password</h3>
              <ul class="list-unstyled password-list m-0">
                <li *ngIf="!otherUsers" class="d-flex align-items-center">
                  <div class="form-group">
                    <label> Current Password</label>
                    <input
                      formControlName="currentPassword"
                      type="password"
                      class="form-control"
                      placeholder="Current Password"
                      value=""
                      [class.is-invalid]="submitted && passwordFormControls.currentPassword.errors"
                    />
                    <div *ngIf="submitted || passwordFormControls.currentPassword.errors" class="invalid-feedback">
                      <div *ngIf="passwordFormControls.currentPassword.errors?.required">
                        Current Password Is Required
                      </div>
                    </div>
                  </div>
                </li>
                <li class="d-flex align-items-center">
                  <div class="form-group">
                    <label> New Password</label>
                    <input
                      formControlName="newPassword"
                      type="password"
                      class="form-control"
                      placeholder="New Password"
                      value=""
                      [class.is-invalid]="submitted && passwordFormControls.newPassword.errors"
                    />
                    <div *ngIf="submitted || passwordFormControls.newPassword.errors" class="invalid-feedback">
                      <div *ngIf="passwordFormControls.newPassword.errors?.required">
                        New Password is Required
                      </div>
                      <div *ngIf="passwordFormControls.newPassword.errors.capital">
                        Password should contain a capital letter<br>
                      </div>
                      <div *ngIf="passwordFormControls.newPassword.errors.small">
                        Password should contain a small letter<br>
                      </div>
                      <div *ngIf="passwordFormControls.newPassword.errors.number">
                        Password should contain a number<br>
                      </div>
                      <div *ngIf="passwordFormControls.newPassword.errors.minlength">
                        Password should contain 8 or more characters<br>
                      </div>
                      <div *ngIf="passwordFormControls.newPassword.errors.special">
                        Password should contain a special character ($&#64;!%*?&)
                      </div>
                    </div>
                  </div>
                </li>
                <li class="d-flex align-items-center">
                  <div class="form-group">
                    <label> Confirm New Password</label>
                    <input
                      formControlName="confirmNewPassword"
                      type="password"
                      class="form-control"
                      placeholder="Confirm  Password"
                      value=""
                      [class.is-invalid]="submitted && passwordFormControls.confirmNewPassword.errors"
                    />
                    <div *ngIf="submitted || passwordFormControls.confirmNewPassword.errors" class="invalid-feedback">
                      <div *ngIf="passwordFormControls.confirmNewPassword.errors?.required">
                        Confirm New Password is Required
                      </div>
                      <div *ngIf="passwordFormControls.confirmNewPassword.errors?.mustMatch">
                        Both New Password Fields must Match
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="card-footer text-right border-0 px-0">
          <button type="submit" class="btn btn-primary">Save</button>
        </div>
      </div>
    </form>
  </div>
</div>
