<div class="tab-content" *ngIf="notificationFormGroup">
  <div class="tab-pane fade show active">
    <div class="card shadow-none">
      <div class="card-body p-0">
        <div class="row">
          <div class="col-lg-6">
            <h3 class="title mb-3">Events</h3>
            <p class="title-small">Select when to get notified via Email</p>
            <ul class="list-unstyled noti-list m-0">
              <ng-container *ngFor="let notificationOption of notifications">
                <ng-container *ngxPermissionsOnly="notificationOption.permission">
                  <li class="d-flex align-items-center" *ngIf="notificationFormGroup.controls[notificationOption.id]">
                    <h4>{{ notificationOption.name }}</h4>
                    <div class="custom-switch ml-auto">
                      <input [formControl]="notificationFormGroup.controls[notificationOption.id]"
                        (change)="onNotificationFormGroupChange()" class="tgl tgl-light tgl-primary" id="{{notificationOption.id}}"
                        type="checkbox" />
                      <label class="tgl-btn m-0" appToggleLabel for="{{notificationOption.id}}"></label>
                    </div>
                  </li>
                </ng-container>
              </ng-container>
            </ul>
          </div>
          <ng-template ngxPermissionsOnly="Publisher">
            <div class="col-lg-6">
              <h3 class="title mb-3">Adspaces</h3>
              <p class="title-small">Select which Adspaces to listen to</p>
              <ul class="list-unstyled noti-list m-0">
                <ng-container *ngFor="let adSpace of adSpaces">
                  <li class="d-flex align-items-center" *ngIf="adSpacesFormGroup.controls[adSpace.id]">
                    <h4>{{ adSpace.name }}</h4>
                    <div class="custom-switch ml-auto">
                      <input [formControl]="adSpacesFormGroup.controls[adSpace.id]"
                        (change)="onAdSpacesFormGroupChange()" class="tgl tgl-light tgl-primary" id="{{adSpace.id}}"
                        type="checkbox" />
                      <label class="tgl-btn m-0" appToggleLabel for="{{adSpace.id}}"></label>
                    </div>
                  </li>
                </ng-container>
              </ul>
            </div>
          </ng-template>
        </div>
      </div>
    </div>
  </div>
</div>
