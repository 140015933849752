import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

import { AccountType } from 'src/app/shared/constants/account';
import { BreadcrumbService } from 'src/app/shared/services/breadcrumb.service';
import { CoreService } from 'src/app/shared/services/core.service';

import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-setting',
  templateUrl: './setting.component.html',
  styleUrls: ['./setting.component.scss'],
})
export class SettingComponent implements OnInit {
  breadCrumbData = [{ name: 'Setting', link: '/setting' }];
  private settingUrl = '/setting';

  public componentParam: string;
  public dataFromChild = null;
  public contentVisible = true;

  constructor(public breadcrumbService: BreadcrumbService,
    public coreService: CoreService,
    public router: Router) {

    router.events
      .pipe(filter((event) => event['routerEvent'] && event['routerEvent'] instanceof NavigationEnd))
      .pipe(filter(() => this.router.url === this.settingUrl))
      .subscribe(() => this.redirectToRelevantLocation());
  }

  ngOnInit(): void {
    this.breadcrumbService.updateBreadcrumbsData(this.breadCrumbData);
  }

  redirectToRelevantLocation() {
    this.contentVisible = false;

    if (this.coreService.getCurrentAccountType() === AccountType.ADMIN) {
      this.router.navigate(['/setting/user-management']);
    } else {
      this.router.navigate(['/setting/account']);
    }

    this.contentVisible = true;
  }
}
