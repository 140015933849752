import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';

import { AccountUploadsSettingsModalComponent } from './account-uploads-settings-modal/account-uploads-settings-modal.component';
import { AccountUploadsSettingsComponent } from './account-uploads-settings/account-uploads-settings.component';
import { AdSpaceSettingsModelComponent } from './ad-space-settings-model/ad-space-settings-model.component';
import { PasswordSettingsComponent } from './password-settings/password-settings.component';

import { AccountSettingsComponent } from 'src/app/modules/setting/account-settings/account-settings.component';
import { AdSpaceSettingsComponent } from 'src/app/modules/setting/ad-space-settings/ad-space-settings.component';
import { AdminSettingsComponent } from 'src/app/modules/setting/admin-settings/admin-settings.component';
import { FinanceSettingsComponent } from 'src/app/modules/setting/finance-settings/finance-settings.component';
import { NotificationSettingsComponent } from 'src/app/modules/setting/notification-settings/notification-settings.component';
import { SettingRoutingModule } from 'src/app/modules/setting/setting-routing.module';
import { SettingSidebarComponent } from 'src/app/modules/setting/setting-sidebar/setting-sidebar.component';
import { SettingComponent } from 'src/app/modules/setting/setting/setting.component';
import {
  UserManagementSettingsModelComponent,
} from 'src/app/modules/setting/user-management-settings-model/user-management-settings-model.component';
import { UserManagementSettingsComponent } from 'src/app/modules/setting/user-management-settings/user-management-settings.component';
import { SharedModule } from 'src/app/modules/shared/shared.module';

import { NgxFileDropModule } from 'ngx-file-drop';
import { NgxPermissionsModule } from 'ngx-permissions';
import { SharedPipesModule } from '../../shared/pipes/pipes.module';

@NgModule({
  declarations: [
    AccountSettingsComponent,
    AdSpaceSettingsComponent,
    AdminSettingsComponent,
    FinanceSettingsComponent,
    UserManagementSettingsComponent,
    SettingSidebarComponent,
    SettingComponent,
    NotificationSettingsComponent,
    AdSpaceSettingsModelComponent,
    UserManagementSettingsModelComponent,
    PasswordSettingsComponent,
    AccountUploadsSettingsComponent,
    AccountUploadsSettingsModalComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    SettingRoutingModule,
    ReactiveFormsModule,
    NgbModule,
    NgSelectModule,
    SharedModule,
    NgxFileDropModule,
    NgxPermissionsModule,
    SharedPipesModule,
  ],
  providers: [],
})
export class SettingModule {}
