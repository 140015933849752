import { HttpErrorResponse } from '@angular/common/http';
import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

import { SettingSection } from 'src/app/modules/setting/settings.base';
import {
  UserManagementSettingsModelComponent,
} from 'src/app/modules/setting/user-management-settings-model/user-management-settings-model.component';
import {
  UserGroup,
  UserStatus,
} from 'src/app/shared/constants/account';
import {
  dropdownSettings,
  IUserRequest,
  userGroupFilterOptions,
  userGroupOptions,
  userStatusFilterOptions,
} from 'src/app/shared/constants/user-management';
import { valWithChecked } from 'src/app/shared/helpers/filters';
import { BreadcrumbService } from 'src/app/shared/services/breadcrumb.service';
import { CoreService } from 'src/app/shared/services/core.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { SettingsService } from 'src/app/shared/services/settings.service';
import { SortService } from 'src/app/shared/services/sort.service';
import { TitleService } from 'src/app/shared/services/title-service';
import { UserManagementService } from 'src/app/shared/services/user-management.service';

import lodash from 'lodash';
import { NgxPermissionsService } from 'ngx-permissions';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-user-management-settings',
  templateUrl: './user-management-settings.component.html',
  styleUrls: ['./user-management-settings.component.scss'],
})
export class UserManagementSettingsComponent
  extends SettingSection implements OnInit, AfterViewInit, OnDestroy {

  filters = {
    group: {
      name: 'User Group',
      enabled: false,
    },

    status: {
      name: 'Status',
      enabled: false,
    },
  };

  breadCrumbData = [
    { name: 'Settings', link: '/setting' },
    { name: 'User Management', link: '/setting/user-management' },
  ];

  private modalReference: NgbModalRef;
  private getUserListSubscription: Subscription;
  private updateUserSubscription: Subscription;
  private currentAccountType;
  private currentAccountId;
  private currentUser;
  private permissions = [];
  public users;
  public userGroupOptions;
  public userGroupFilterOptions;
  public userStatusFilterOptions;
  public dropdownSettings;
  public selectStatus;
  public readOnly = false;

  searchText = new UntypedFormControl('');
  group = new UntypedFormControl('');
  status = new UntypedFormControl('');

  filterForm = new UntypedFormGroup({
    searchText: this.searchText,
    status: this.status,
    group: this.group,
  });

  public sort = {
    field: null,
    direction: null,
  };

  constructor(
    public coreService: CoreService,
    private breadcrumbService: BreadcrumbService,
    private modalService: NgbModal,
    private userManagementService: UserManagementService,
    private notificationService: NotificationService,
    private permissionsService: NgxPermissionsService,
    private settingService: SettingsService,
    private titleService: TitleService,
    private sortService: SortService,
  ) {
    super();

    if (this.coreService.settingsLocation === 'Account-Admin') {
      this.assignStaticData();
      this.currentAccountType = this.coreService.editAccountType;
      this.currentAccountId = this.coreService.editUserAccountId;
      this.permissions.push(this.currentAccountType);
      this.permissionsService.loadPermissions(this.permissions);
    } else {
      this.assignStaticData();
      this.currentAccountType = this.coreService.getCurrentAccountType();
      this.currentAccountId = this.coreService.getCurrentAccountId();
      this.permissions.push(this.currentAccountType);
      this.permissionsService.loadPermissions(this.permissions);
    }

    this.assignDynamicOptions();
  }

  assignStaticData() {
    this.userGroupOptions = userGroupOptions;
    this.dropdownSettings = dropdownSettings;
    this.userStatusFilterOptions = userStatusFilterOptions;
    this.userGroupFilterOptions = userGroupFilterOptions;
  }

  async assignDynamicOptions() {
    this.selectStatus = await this.settingService
      .getUserManagementStatusOptions();
  }

  ngAfterViewInit(): void {
    this.getUserList();
  }

  async ngOnInit(): Promise<void> {
    this.titleService.setTitle('Setting - User Management');
    this.breadcrumbService.updateBreadcrumbsData(this.breadCrumbData);

    this.applyAccessScope();
    this.currentUser = await this.coreService.getCurrentUserDetailsLazy();
  }

  async applyAccessScope() {
    await this.applyAccessScopeFlags();
  }

  useAccountManagerAccessScope() {
    this.readOnly = true;
  }

  ngOnDestroy(): void {
    this.unsubscribeObject(this.getUserListSubscription);
    this.unsubscribeObject(this.updateUserSubscription);
  }

  buildQuery() {
    let query: any = {
      group: valWithChecked(
        this.filters.group.enabled,
        this.filterForm.value.group,
      ),

      status: valWithChecked(
        this.filters.status.enabled,
        this.filterForm.value.status,
      ),
    };

    query = lodash.fromPairs(
      Object.entries(query).filter(([_, value]) => {
        return value;
      }),
    );

    if (this.searchText.value) {
      query = {
        ...query,
        search: this.searchText.value,
      };
    }

    if (this.sort.field && this.sort.direction) {
      query = {
        ...this.sortService.param(
          this.sort.field,
          this.sort.direction,
        ),
        ...query,
      };
    }

    return query;
  }

  handleOnApply() {
    this.getUserList();
  }

  handleOnSort({ name, direction }) {
    this.sort = {
      direction,
      field: name,
    };

    this.getUserList();
  }

  openModel(user?: any) {
    this.modalReference = this.modalService.open(UserManagementSettingsModelComponent, {
      centered: true,
      scrollable: true,
    });

    const canBePromoted = (
      this.currentUser?.is_primary
      && user?.status === UserStatus.ACTIVE
      && user?.groups?.includes(UserGroup.ADMIN)
    );

    const component = this.modalReference.componentInstance as any;
    component.userId = user?.id;
    component.isPrimary = user?.is_primary ?? false;
    component.canBePromoted = canBePromoted ?? false;

    this.modalReference.result.then(
      () => this.getUserList(),
      () => this.getUserList(),
    );
  }

  getUserList(skipCache = true) {
    this.getUserListSubscription = this.userManagementService
      .getUserList(this.currentAccountId, this.buildQuery(), skipCache)
      .subscribe(
        (userData: any) => {
          this.users = userData;
        },
        (error: HttpErrorResponse) => {
          this.notificationService.showError(error?.message);
        },
      );
  }

  updateUserGroup(newGroup, userId) {
    if (!newGroup) {
      return;
    }

    const payload = {
      groups: [newGroup.id],
    } as IUserRequest;

    this.updateUserSubscription = this.userManagementService
      .updateUser(userId, payload)
      .subscribe(
        () => {
          this.notificationService
            .showSuccess('Group Updated Successfully');

          this.getUserList();
        },
        (error: HttpErrorResponse) => {
          this.notificationService.showError(error?.message);
        },
      );
  }

  updateUserStatus(data, id) {
    const finalData = {} as IUserRequest;
    finalData.status = data.id;

    this.updateUserSubscription = this.userManagementService
      .updateUser(id, finalData)
      .subscribe(
        () => {
          this.notificationService
            .showSuccess('Status Updated Successfully');

          this.getUserList();
        },
        (error: HttpErrorResponse) => {
          this.notificationService.showError(error?.message);
        },
      );
  }

  getUserGroup(user) {
    const groups = lodash.get(user, 'groups', []);
    return lodash.get(groups, 0, null);
  }

  unsubscribeObject(object: Subscription) {
    if (object) {
      object.unsubscribe();
    }
  }

  updateFilterSelection(name: string, enabled: boolean) {
    lodash.set(this.filters, `${name}.enabled`, enabled);
  }
}
