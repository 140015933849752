<form [formGroup]="accountUploadForm" (ngSubmit)="onSubmit()">
  <div class="modal-header">
    <h4 class="modal-title">Upload file</h4>
  </div>

  <div class="modal-body">
    <div class="form-group">
      <label>File</label>
      <ngx-file-drop dropZoneLabel="Drop file here" (onFileDrop)="dropped($event)" multiple="false">
        <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
          <div class="import-click" (click)="openFileSelector()"></div>
          Drop File Here
        </ng-template>
      </ngx-file-drop>
      <div class="text-center">
          {{ fileName }}
      </div>
    </div>
    <div class="form-group">
      <label>Name</label>
      <input formControlName="name" type="text" class="form-control" placeholder="Name" required />
    </div>
    <div class="form-group">
      <label>Description</label>
      <input formControlName="description" type="text" class="form-control" placeholder="Description" />
    </div>
  </div>

  <div class="modal-footer text-right">
    <button class="btn btn-secondary mr-10" (click)="cancel()" type="button">
      Cancel
    </button>
    <button class="btn btn-primary">
      Add
    </button>
  </div>
</form>
