<div class="modal-header">
  <h4 class="modal-title">{{ (userId ? 'Edit User' : 'Add New User') }}</h4>
</div>
<div class="modal-body">
  <form [formGroup]="userManagementSettingForm" (ngSubmit)="onSubmit()">
    <div class="row">
      <div class="col-12">
        <div class="form-group">
          <label>Status </label>

          <ng-select
            bindLabel="name"
            bindValue="id"
            [items]="selectStatus"
            [readonly]="isPrimary"
            formControlName="status"
            placeholder="Select"
            class="expanding-dropdown-panel"
          >
          </ng-select>
        </div>

        <div class="form-group">
          <label>
            Email
          </label>

          <input
            type="email"
            formControlName="email"
            class="form-control"
            placeholder="Email"
            [class.is-invalid]="submitted && userDataGroupControl('first_name').errors"
          />

          <div *ngIf="submitted && userDataGroupControl('email').errors" class="invalid-feedback">
            <div *ngIf="userDataGroupControl('email').errors.required">
              Email is required
            </div>

            <div *ngIf="userDataGroupControl('email').errors.email">
              Email must be a valid email address
            </div>
          </div>
        </div>

        <div class="form-group">
          <label> First Name</label>

          <input
            type="text"
            formControlName="first_name"
            class="form-control"
            placeholder="First Name"
            [class.is-invalid]="submitted && userDataGroupControl('first_name').errors"
          />

          <div *ngIf="submitted && userDataGroupControl('first_name').errors" class="invalid-feedback">
            <div *ngIf="userDataGroupControl('first_name').errors.required">
              First Name is required
            </div>
          </div>
        </div>

        <div class="form-group">
          <label> Last Name</label>

          <input
            type="text"
            formControlName="last_name"
            class="form-control"
            placeholder="Last Name"
            [class.is-invalid]="submitted && userDataGroupControl('last_name').errors"
          />

          <div *ngIf="submitted && userDataGroupControl('last_name').errors" class="invalid-feedback">
            <div *ngIf="userDataGroupControl('last_name').errors.required">
              Last Name is required
            </div>
          </div>
        </div>

        <div class="form-group">
          <label>Phone Number</label>

          <div class="input-group">
            <ng-select
              class="country-dial-code expanding-dropdown-panel mr-2"
              [items]="countryDialCodeOptions"
              formControlName="phone_country_code"
              bindLabel="name"
              bindValue="dialCode"
              style="width: 50% !important;"
            >
              <ng-template ng-label-tmp let-item="item">
                <span>{{ item.name }} ({{ item.dialCode }})</span>
              </ng-template>

              <ng-template ng-option-tmp let-item="item">
                <span>{{ item.name }} ({{ item.dialCode }})</span>
              </ng-template>
            </ng-select>

            <input
              type="text"
              class="form-control"
              formControlName="phone"
              placeholder="Phone"
              [value]="selectedCountryCode"
            />
          </div>
        </div>

        <div class="form-group">
          <label>User Group </label>

          <ng-select
            bindLabel="name"
            bindValue="id"
            [items]="userGroupOptions"
            [readonly]="isPrimary"
            formControlName="groups"
            placeholder="Select"
            class="expanding-dropdown-panel"
          >
          </ng-select>
        </div>

        <div *ngIf="canBePromoted && !isPrimary" class="d-flex form-group">
          <label for="promote">
            Promote to Account Owner
          </label>

          <div class="custom-switch ml-auto">
            <input
              id="promote-checkbox"
              type="checkbox"
              formControlName="promote_to_account_owner"
              class="tgl tgl-light tgl-primary"
            />

            <label class="tgl-btn m-0" appToggleLabel for="promote-checkbox"></label>
          </div>
        </div>
      </div>
    </div>

    <div class="modal-footer text-right">
      <button *ngIf="userId && !isPrimary" class="btn btn-secondary m-r-10" (click)="resetPassword()" type="button">
        Reset Password
      </button>

      <button class="btn btn-secondary m-r-10" (click)="activeModal.close('Close click')" type="button">
        Cancel
      </button>

      <button class="btn btn-primary" type="submit">
        Save
      </button>
    </div>
  </form>
</div>
