import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { SettingSection } from 'src/app/modules/setting/settings.base';
import {
  AccountType,
  IAccountRequest,
  IAccountResponse,
  IAddress,
  IBankingInformation,
  IBillingInformation,
  ISendBills,
  payoutMethodOptions,
  vatTypeOptions,
} from 'src/app/shared/constants/account';
import { AccountService } from 'src/app/shared/services/account.service';
import { BreadcrumbService } from 'src/app/shared/services/breadcrumb.service';
import { CoreService } from 'src/app/shared/services/core.service';
import { DataService } from 'src/app/shared/services/data.service';
import { DurationService } from 'src/app/shared/services/duration.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { TitleService } from 'src/app/shared/services/title-service';

import { Duration } from 'luxon';
import { NgxPermissionsService } from 'ngx-permissions';
import { Subscription } from 'rxjs';

enum BankType {
  SEPA,
  INTERNATIONAL,
}

const minimumPayoutThresholdFor = (t: BankType) => {
  switch (t) {
    case BankType.SEPA:
      return 50;
    case BankType.INTERNATIONAL:
      return 200;
  }
};

@Component({
  selector: 'app-finance-settings',
  templateUrl: './finance-settings.component.html',
  styleUrls: ['./finance-settings.component.scss'],
})
export class FinanceSettingsComponent
  extends SettingSection implements OnInit, OnDestroy {

  constructor(
    public coreService: CoreService,
    private breadcrumbService: BreadcrumbService,
    private fb: UntypedFormBuilder,
    private accountService: AccountService,
    private notificationService: NotificationService,
    private permissionsService: NgxPermissionsService,
    private dataService: DataService,
    private route: ActivatedRoute,
    private router: Router,
    private titleService: TitleService,
    private durationService: DurationService,
  ) {
    super();

    if (this.coreService.settingsLocation === 'Account-Admin') {
      this.isAdminEditing = true;
      this.currentAccountType = this.coreService.editAccountType;
      this.currentAccountId = this.coreService.editUserAccountId;
    } else {
      this.isAdminEditing = false;
      this.currentAccountType = this.coreService.getCurrentAccountType();
      this.currentAccountId = this.coreService.getCurrentAccountId();
    }

    this.permissions.push(this.currentAccountType);
    this.permissionsService.loadPermissions(this.permissions);
  }

  get minimumPayoutThreshold() {
    return minimumPayoutThresholdFor(this.bankingGroupControl('bankType').value);
  }

  get feesFormGroupControl() {
    return this.financeSettingForm.get('billingSettings');
  }

  get sendBillsFormArrayControl() {
    return this.financeSettingForm.get('sendBills')['controls'] as UntypedFormArray;
  }

  get sendBills(): UntypedFormArray {
    return this.financeSettingForm.get('sendBills') as UntypedFormArray;
  }

  get nextUrl() {
    return this.route.snapshot.queryParams.returnUrl;
  }

  breadCrumbData = [
    { name: 'Settings', link: '/setting/account' },
    { name: 'Finance Settings', link: '/setting/finance-settings' },
  ];

  public submitted = false;
  public permissions = [];
  public isAdminEditing = false;  // whether the admin is editing this page
  private getAccountSubscription: Subscription;

  public readOnly = false;
  private currentAccountType: string;
  private currentAccountId: number;

  payoutMethodOptionsData = [];
  countryOptionsData = [];

  public vatDisabled = false;

  public financeSettingForm: UntypedFormGroup = this.fb.group({
    accountId: [''],
    sendBills: this.fb.array([]),
    taxSettings: this.fb.group({
      isSalesTaxDeductionEligible: [false],
      vatId: [''],
      vatType: [''],
    }),
    billingAddress: this.fb.group({
      billingAddressId: [''],
      streetNameAndNumber: ['', Validators.required],
      buildingNameAndNumber: [''],
      zipCode: ['', Validators.required],
      billingCity: ['', Validators.required],
      billingCountry: ['', Validators.required],
    }),
    billingSettings: this.fb.group({
      defaultDueDate: [''],
    }),
    payout: this.fb.group({
      payoutMethod: [''],
      payoutThreshold: [
        minimumPayoutThresholdFor(BankType.SEPA),
        [Validators.required, Validators.min(minimumPayoutThresholdFor(BankType.SEPA))],
      ],
    }),
    banking: this.fb.group({
      accountName: ['', Validators.required],
      bankType: [BankType.SEPA, Validators.required],
      bankingId: [''],
      bankingAddressId: [''],
      accountNo: [''],
      iban: ['', Validators.required],
      bicSwift: ['', Validators.required],
      creditInstitutionName: ['', Validators.required],
      street: [''],
      streetNumber: [''],
      bankingZip: [''],
      bankingCity: ['', Validators.required],
      bankingCountry: ['', Validators.required],
    }),
    fees: this.fb.group({
      split: [''],
      recurringFees: [''],
    }),
  });
  public vatTypeOptionsData = vatTypeOptions;

  bankTypes = [
    { name: 'SEPA (recommended)', value: BankType.SEPA },
    { name: 'International (non SEPA)', value: BankType.INTERNATIONAL },
  ];

  public readonly BankType = BankType;

  addressGroupControl(controlName: string) {
    return this.financeSettingForm.get('billingAddress').get(controlName);
  }

  bankingGroupControl(controlName: string) {
    return this.financeSettingForm.get('banking').get(controlName);
  }

  isInternationalSelected() {
    return this.bankingGroupControl('bankType').value === BankType.INTERNATIONAL;
  }

  isSEPASelected() {
    return this.bankingGroupControl('bankType').value === BankType.SEPA;
  }

  ngOnInit(): void {
    this.titleService.setTitle('Setting - Finance Settings');
    this.payoutMethodOptionsData = payoutMethodOptions;
    this.breadcrumbService.updateBreadcrumbsData(this.breadCrumbData);

    if (this.currentAccountType === AccountType.PUBLISHER) {
      this.getPublisherAccount();
    }

    if (this.currentAccountType === AccountType.ADVERTISER ||
      this.currentAccountType === AccountType.ADMIN) {
      this.getAdvertiserAccount();
    }

    if (this.currentAccountType === AccountType.ADVERTISER) {
      this.financeSettingForm.get('banking').disable();
    }

    if (this.vatDisabled) {
      this.financeSettingForm.patchValue({
        taxSettings: {
          vatId: '',
          vatType: '',
          isSalesTaxDeductionEligible: false,
        },
      });

      this.financeSettingForm.get('taxSettings').disable();
    }

    this.countryOptionsData = this.dataService.countrySelectItems();
    this.applyAccessScope();
  }

  async applyAccessScope() {
    await this.applyAccessScopeFlags();

    if (this.readOnly) {
      this.financeSettingForm.disable();
    }
  }

  updateValidators(value) {
    const payoutThresholdControl = this.financeSettingForm.get('payout').get('payoutThreshold');
    const minimumThreshold = minimumPayoutThresholdFor(value);
    const internationalFormControls = [
      this.bankingGroupControl('accountNo'),
      this.bankingGroupControl('street'),
      this.bankingGroupControl('streetNumber'),
      this.bankingGroupControl('bankingZip'),
    ];
    const sepaFormControls = [this.bankingGroupControl('iban')];

    const clearValidators = (x: UntypedFormControl) => {
      x.clearValidators();
      x.updateValueAndValidity();
    };
    const makeRequired = (x: UntypedFormControl) => {
      x.setValidators([Validators.required]);
      x.updateValueAndValidity();
    };

    switch (value) {
      case BankType.SEPA:
        internationalFormControls.forEach(clearValidators);
        sepaFormControls.forEach(makeRequired);
        break;
      case BankType.INTERNATIONAL:
        sepaFormControls.forEach(clearValidators);
        internationalFormControls.forEach(makeRequired);
        break;
    }

    payoutThresholdControl.setValidators([Validators.required, Validators.min(minimumThreshold)]);
    if (payoutThresholdControl.value < minimumThreshold) {
      payoutThresholdControl.setValue(minimumThreshold);
    }
    payoutThresholdControl.updateValueAndValidity();
  }

  useAccountManagerAccessScope(accountType) {
    this.readOnly = accountType === AccountType.PUBLISHER;
  }

  onSubmit() {
    this.submitted = true;

    if (this.financeSettingForm.valid) {
      if (this.currentAccountType === AccountType.PUBLISHER) {
        this.updatePublisherAccount();
      }

      if (this.currentAccountType === AccountType.ADVERTISER ||
        this.currentAccountType === AccountType.ADMIN) {
        this.updateAdvertiserAccount();
      }
    }
  }

  ngOnDestroy(): void {
    if (this.getAccountSubscription) {
      this.getAccountSubscription.unsubscribe();
    }
  }

  initSendBills() {
    return this.fb.group({
      sendBillId: [''],
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
    });
  }

  getPublisherAccount() {
    this.getAccountSubscription = this.accountService
      .getPublisherAccount(this.currentAccountId)
      .subscribe((accountData: any) => {

        this.vatDisabled = accountData?.account?.business_type === 'Private';

        if (accountData?.account?.billing_email_info.length === 0) {
          this.addSendBills();
        }

        this.setSendBillValues(accountData?.account?.billing_email_info);
        const isInternational = Boolean(accountData?.account?.payment_bank?.account_number);
        this.financeSettingForm.patchValue({
          accountId: accountData.account.id,
          taxSettings: {
            vatId: accountData?.account?.billing_vat_id,
            vatType: accountData?.account?.vat_type,
            isSalesTaxDeductionEligible: accountData?.account?.billing_apply_vat,
          },
          billingAddress: {
            billingAddressId: accountData?.account?.billing_address?.id,
            streetNameAndNumber: accountData?.account?.billing_address?.line1,
            buildingNameAndNumber: accountData?.account?.billing_address?.line2,
            zipCode: accountData?.account?.billing_address?.zip_code,
            billingCity: accountData?.account?.billing_address?.city,
            billingCountry: accountData?.account?.billing_address?.country,
          },
          banking: {
            accountName: accountData?.account?.payment_bank?.account_name,
            bankType: isInternational ? BankType.INTERNATIONAL : BankType.SEPA,
            bankingId: accountData?.account?.payment_bank?.id,
            bankingAddressId: accountData?.account?.payment_bank?.bank_address?.id,
            accountNo: accountData?.account?.payment_bank?.account_number,
            iban: accountData?.account?.payment_bank?.account_international_number,
            bicSwift: accountData?.account?.payment_bank?.bank_international_number,
            creditInstitutionName: accountData?.account?.payment_bank?.bank_name,
            street: accountData?.account?.payment_bank?.bank_address?.line1,
            streetNumber: accountData?.account?.payment_bank?.bank_address?.line2,
            bankingCity: accountData?.account?.payment_bank?.bank_address?.city,
            bankingCountry: accountData?.account?.payment_bank?.bank_address?.country,
            bankingZip: accountData?.account?.payment_bank?.bank_address?.zip_code,
          },
          payout: {
            payoutMethod: accountData?.payout_method,
            payoutThreshold: accountData?.payout_threshold,
          },
        });

        if (
          accountData?.account?.payment_bank?.bank_address?.line1 === 'SEPA' ||
          accountData?.account?.payment_bank?.bank_address?.line2 === 'SEPA' ||
          accountData?.account?.payment_bank?.bank_address?.zip_code === 'SEPA'
        ) {
          this.financeSettingForm.patchValue({
            banking: {
              street: null,
              streetNumber: null,
              bankingZip: null,
            },
          });
        }

        this.bankingGroupControl('bankType').valueChanges.subscribe((type) => this.updateValidators(type));
        this.updateValidators(this.bankingGroupControl('bankType').value);
      }, (error: HttpErrorResponse) => {
        this.notificationService.showError(error?.message);
      });

  }

  getAdvertiserAccount() {
    this.getAccountSubscription = this.accountService
      .getAdvertiserAccount(this.currentAccountId)
      .subscribe((accountData: any) => {
        if (accountData?.account?.billing_email_info.length === 0) {
          this.addSendBills();
        }

        this.setSendBillValues(accountData?.account?.billing_email_info);
        this.financeSettingForm.patchValue({
          accountId: accountData.account.id,
          taxSettings: {
            vatId: accountData?.account?.billing_vat_id,
            vatType: accountData?.account?.vat_type,
            isSalesTaxDeductionEligible: accountData?.account?.billing_apply_vat,
          },
          billingAddress: {
            billingAddressId: accountData?.account?.billing_address?.id,
            streetNameAndNumber: accountData?.account?.billing_address?.line1,
            buildingNameAndNumber: accountData?.account?.billing_address?.line2,
            zipCode: accountData?.account?.billing_address?.zip_code,
            billingCity: accountData?.account?.billing_address?.city,
            billingCountry: accountData?.account?.billing_address?.country,
          },
          banking: {
            bankingId: accountData?.account?.payment_bank?.id,
            bankingAddressId: accountData?.account?.payment_bank?.bank_address?.id,
            accountNo: accountData?.account?.payment_bank?.account_number,
            iban: accountData?.account?.payment_bank?.account_international_number,
            bicSwift: accountData?.account?.payment_bank?.bank_international_number,
            creditInstitutionName: accountData?.account?.payment_bank?.bank_name,
            street: accountData?.account?.payment_bank?.bank_address?.line1,
            streetNumber: accountData?.account?.payment_bank?.bank_address?.line2,
            bankingCity: accountData?.account?.payment_bank?.bank_address?.city,
            bankingCountry: accountData?.account?.payment_bank?.bank_address?.country,
            bankingZip: accountData?.account?.payment_bank?.bank_address?.zip_code,
          },
          fees: {
            split: accountData?.commision_fee_split,
            recurringFees: accountData?.recurring_fee_value,
          },
          billingSettings: {
            defaultDueDate: this.durationService
              ?.parse(accountData?.billing_due_duration)
              ?.as('days'),
          },
        });

      }, (error: HttpErrorResponse) => {
        this.notificationService.showError(error?.message);
      });
  }

  updatePublisherAccount() {
    const data = this.formateData(this.financeSettingForm.value);

    this.accountService
      .updatePublisherAccount(this.currentAccountId, data)
      .subscribe((accountData: any) => {
        if (accountData) {
          this.notificationService.showSuccess(
            'Account Setting Update Successfully',
          );
          if (this.nextUrl) {
            this.router.navigate([this.nextUrl]);
          }
        }
      }, (error: HttpErrorResponse) => {
        this.notificationService.showError(error?.message);
        this.coreService.showError('Account Setting Not Updated');
      });
  }

  updateAdvertiserAccount() {
    const data = this.formateData(this.financeSettingForm.value);

    this.accountService
      .updateAdvertiserAccount(this.currentAccountId, data)
      .subscribe((accountData: any) => {
        if (accountData) {
          this.notificationService.showSuccess(
            'Account Setting Update Successfully',
          );
          if (this.nextUrl) {
            this.router.navigate([this.nextUrl]);
          }
        }
      }, (error: HttpErrorResponse) => {
        this.notificationService.showError(error?.message);
        this.coreService.showError('Account Setting Not Updated');
      });
  }

  formateData(formData) {
    const accountData = {} as IAccountResponse;
    accountData.id = formData.accountId;
    accountData.billing_vat_id = formData.taxSettings?.vatId;
    accountData.vat_type = formData.taxSettings?.vatType;
    accountData.billing_apply_vat = formData.taxSettings?.isSalesTaxDeductionEligible;

    if (formData.sendBills) {
      accountData.billing_email_info = formData.sendBills.map((value) => {
        const obj = {} as IBillingInformation;

        obj.id = value.sendBillId;
        obj.email = value.email;
        obj.first_name = value.firstName;
        obj.last_name = value.lastName;

        return obj;
      });
    }

    // TEMPFIX: If the address is empty, and all values are NULL - the backend will
    //          raise an error about all the mandatory fields in an address being empty.
    //          If all fields are empty - don't send the address
    if (formData.billingAddress && Object.values(formData.billingAddress).some((x) => !!x)) {
      accountData.billing_address = {} as IAddress;
      accountData.billing_address.id = formData.billingAddress.billingAddressId;
      accountData.billing_address.line1 = formData.billingAddress.streetNameAndNumber;
      accountData.billing_address.line2 = formData.billingAddress.buildingNameAndNumber;
      accountData.billing_address.zip_code = formData.billingAddress.zipCode;
      accountData.billing_address.city = formData.billingAddress.billingCity;
      accountData.billing_address.country = formData.billingAddress.billingCountry;
    }

    if (formData.banking) {
      accountData.payment_bank = {} as IBankingInformation;
      accountData.payment_bank.id = formData.banking?.bankingId;
      accountData.payment_bank.account_name = formData.banking?.accountName;
      accountData.payment_bank.bank_name = formData.banking?.creditInstitutionName;
      accountData.payment_bank.bank_international_number = formData.banking?.bicSwift;

      switch (formData.banking?.bankType) {
        case BankType.SEPA:
          accountData.payment_bank.account_international_number = formData.banking?.iban;
          accountData.payment_bank.account_number = '';
          break;
        case BankType.INTERNATIONAL:
          accountData.payment_bank.account_international_number = '';
          accountData.payment_bank.account_number = formData.banking?.accountNo;
          break;
      }

      const bankAddress = {} as IAddress;
      bankAddress.id = formData.banking?.bankingAddressId;
      bankAddress.city = formData.banking?.bankingCity;
      bankAddress.country = formData.banking?.bankingCountry;

      switch (formData.banking?.bankType) {
        case BankType.SEPA:
          bankAddress.line1 = 'SEPA';
          bankAddress.line2 = 'SEPA';
          bankAddress.zip_code = 'SEPA';
          break;
        case BankType.INTERNATIONAL:
          bankAddress.line1 = formData.banking?.street;
          bankAddress.line2 = formData.banking?.streetNumber;
          bankAddress.zip_code = formData.banking?.bankingZip;
          break;
      }

      // TEMPFIX: If the address is empty, and all values are NULL - the backend will
      //          raise an error about all the mandatory fields in an address being empty.
      //          If all fields are empty - don't send the address
      if (bankAddress && Object.values(bankAddress).some((x) => !!x)) {
        accountData.payment_bank.bank_address = bankAddress;
      }
    }

    const data = {} as IAccountRequest;
    data.account = accountData;

    if (this.currentAccountType === AccountType.PUBLISHER ||
      this.currentAccountType === AccountType.ADMIN) {
      data.payout_method = formData.payout.payoutMethod;
      data.payout_threshold = formData.payout.payoutThreshold;
    }

    if ((this.currentAccountType === AccountType.ADVERTISER ||
      this.currentAccountType === AccountType.ADMIN) &&
      this.isAdminEditing) {
      data.billing_due_duration = formData.billingSettings.defaultDueDate
        ? Duration
            .fromObject({
              days: parseFloat(formData.billingSettings.defaultDueDate),
            })
            .as('seconds')
        : null;
      data.commision_fee_split = formData.fees?.split;
      data.recurring_fee_value = formData.fees?.recurringFees;
    }
    return data;
  }

  formateSendBill(sendBillsData) {
    const sendBills = sendBillsData.map((value) => {
      const obj = {} as ISendBills;
      obj.sendBillId = value.id;
      obj.email = value.email;
      obj.firstName = value.first_name;
      obj.lastName = value.last_name;
      this.addSendBills();  // side-effect: Add form item
      return obj;
    });
    return sendBills;
  }

  setSendBillValues(sendBillsData) {
    const data = this.formateSendBill(sendBillsData);
    this.sendBills.patchValue(data);
  }

  addSendBills() {
    const control = this.financeSettingForm.controls['sendBills'] as UntypedFormArray;
    control.push(this.initSendBills());
  }

  removeSendBills(index: number) {
    const control = this.financeSettingForm.controls['sendBills'] as UntypedFormArray;
    control.removeAt(index);
  }

  formControlLength() {
    return this.financeSettingForm.get('sendBills')['controls'].length;
  }

  // VALIDATION
  validateSendBills(controller, index) {
    const control = this.sendBillsFormArrayControl[index];

    return (this.submitted && !!control.get(controller).errors) ?
      'is-invalid' : null;
  }

  validateFees(controller) {
    switch (controller) {
      case 'recurringFees':
        return !!this.feesFormGroupControl.get('recurringFees').errors;
    }
  }
}
