import { UserGroup } from 'src/app/shared/constants/account';
import { CoreService } from 'src/app/shared/services/core.service';

import lodash from 'lodash';

export abstract class SettingSection {
  abstract readOnly: boolean;
  abstract coreService: CoreService;

  async applyAccessScopeFlags() {
    const details = await this.coreService.getCurrentUserDetailsLazy();
    const accountType = this.coreService.getCurrentAccountType();

    const groups = lodash.get(details, 'groups', []);
    await this.applyAccessScopeFlagsForUser(accountType, groups);
  }

  applyAccessScopeFlagsForUser(accountType, groups: any[]) {
    this.readOnly = false;

    if (groups.includes(UserGroup.ADMIN)) {
      return this.useAdminAccessScope(accountType);
    }

    if (groups.includes(UserGroup.ACCOUNT_MANAGER)) {
      return this.useAccountManagerAccessScope(accountType);
    }

    return this.useGuestAccessScope(accountType);
  }

  useAdminAccessScope(_accountType) {
    this.readOnly = false;
  }

  useAccountManagerAccessScope(_accountType) {
    this.readOnly = false;
  }

  useGuestAccessScope(_accountType) {
    this.readOnly = true;
  }
}
