import { Component, Input } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { AccountService } from 'src/app/shared/services/account.service';
import { IndicatorService } from 'src/app/shared/services/indicator.service';

import { FileSystemFileEntry, NgxFileDropEntry } from 'ngx-file-drop';

@Component({
  selector: 'app-account-uploads-settings-modal',
  templateUrl: './account-uploads-settings-modal.component.html',
  styleUrls: ['./account-uploads-settings-modal.component.scss'],
})
export class AccountUploadsSettingsModalComponent {
  @Input() accountId: number;

  public fileName = '';

  file = new UntypedFormControl('', [Validators.required]);
  name = new UntypedFormControl('', [Validators.required]);
  description = new UntypedFormControl('');

  accountUploadForm = new UntypedFormGroup({
    file: this.file,
    name: this.name,
    description: this.description,
  });

  constructor(
    public activeModal: NgbActiveModal,
    public accountService: AccountService,
    public indicatorService: IndicatorService,
  ) { }

  async dropped(fileDroppedEntries: NgxFileDropEntry[]) {
    for (const droppedFile of fileDroppedEntries) {
      const fileEntry = (droppedFile.fileEntry as FileSystemFileEntry);
      fileEntry.file((file: File) => {
        this.fileName = file.name;
        this.file.setValue(file);
      });
    }

    this.name.setValue(this.fileName);
  }

  async onSubmit() {
    const body = new FormData();
    body.set('name', this.accountUploadForm.value.name);
    body.set('description', this.accountUploadForm.value.description);
    body.set('file', this.accountUploadForm.value.file);
    body.set('account', this.accountId.toString());

    await this.accountService.createAccountUpload(body)
      .then(
        () => {
          this.indicatorService.success(null, 'File uploaded successfully!');
          this.activeModal.close(true);
        },
        this.indicatorService.failureIndicator(),
      );
  }

  cancel() {
    this.activeModal.close(false);
  }
}
