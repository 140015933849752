import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { AccountType } from 'src/app/shared/constants/account';
import { BreadcrumbService } from 'src/app/shared/services/breadcrumb.service';
import { CoreService } from 'src/app/shared/services/core.service';

import { NgxPermissionsService } from 'ngx-permissions';

@Component({
  selector: 'app-setting-sidebar',
  templateUrl: './setting-sidebar.component.html',
  styleUrls: ['./setting-sidebar.component.scss'],
})
export class SettingSidebarComponent implements OnInit {
  activeSettingTab: string;

  public currentAccountType = null;
  public applicableAccountType = null;

  private permissions = [];
  public componentType;

  constructor(
    private breadcrumbService: BreadcrumbService,
    private coreService: CoreService,
    private permissionsService: NgxPermissionsService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.currentAccountType = this.coreService.getCurrentAccountType();
    this.applicableAccountType = this.coreService.settingsLocation === 'Account-Admin'
      ? this.coreService.editAccountType
      : this.currentAccountType;

    this.breadcrumbService.breadcrumbsData.subscribe(
      (data) => (this.componentType = data),
    );

    this.permissions.push(this.applicableAccountType);
    this.permissionsService.loadPermissions(this.permissions);

    this.activeSettingTab = this.router.url;
  }

  public get isCurrentAccountAdmin() {
    return this.currentAccountType === AccountType.ADMIN;
  }

  navigateToSetting() {
    this.router.navigateByUrl(this.activeSettingTab);
  }
}
