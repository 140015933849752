<div class="tab-content">
  <div class="tab-pane fade show active">
    <div class="card shadow-none">
      <div class="form-group">
        <label class="ml-2">
          First Account Manager
        </label>

        <ng-select
          class="expanding-dropdown-panel"
          placeholder="Select"
          bindLabel="name"
          bindValue="id"
          [items]="managerOptions"
          [(ngModel)]="first_account_manager"
          appendTo="body"
        >
        </ng-select>
      </div>

      <div class="form-group">
        <label class="ml-2">
          Second Account Manager
        </label>

        <ng-select
          class="expanding-dropdown-panel"
          placeholder="Select"
          bindLabel="name"
          bindValue="id"
          [items]="managerOptions"
          [(ngModel)]="second_account_manager"
          appendTo="body"
        >
        </ng-select>
      </div>

      <div class="form-group">
        <label class="ml-2">
          Sales Manager
        </label>

        <ng-select
          class="expanding-dropdown-panel"
          placeholder="Select"
          bindLabel="name"
          bindValue="id"
          [items]="managerOptions"
          [(ngModel)]="sales_manager"
          appendTo="body"
        >
        </ng-select>
      </div>

      <div class="card-footer text-right border-0 px-0">
        <button type="submit" class="btn btn-primary" (click)="handleOnSave()">
          Save
        </button>
      </div>
    </div>
  </div>
</div>
