import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SortService {
  // The field name that should be used for ordering setting the
  // ordering in the backend api invocations.
  public field = 'ordering';

  /*
    Returns the name of the field in the syntax that the backend
    understands.

    TODO: This needs to be gone too, the filter system has this support.
  */
  toValue(name: string, direction: string) {
    if (!name || !direction) {
      return null;
    }

    const prefix = direction === 'desc'
      ? '-'
      : '';

    return `${prefix}${name}`;
  }

  /*
    Returns the direction and field name for a given sort value.
    TODO: This needs to be gone too, the filter system has this support.
  */
  reverseValue(value: string) {
    return {
      direction: value.startsWith('-')
        ? 'desc'
        : 'asc',
      field: value.startsWith('-')
        ? value.substr(1)
        : value,
    };
  }

  param(a: any, b: any) {
    console.error('no, this needs to be gone', a, b);
    return {};
  }
}
