<div class="tab-content">
  <div class="tab-pane fade show active">
    <form [formGroup]="financeSettingForm" (ngSubmit)="onSubmit()">
      <div class="card shadow-none">
        <div class="card-body p-0">
          <div class="row">
            <div class="col-lg-4">
              <!-- Send Bills To -->
              <h3 class="title mb-3">Send Bills To</h3>
              <p class="title-small">
                You can enter multiple email addresses to which your bills will be sent.
              </p>
              <div formArrayName="sendBills">
                <div *ngFor="let sendBillFormArray of sendBillsFormArrayControl; let index = index">
                  <div class="d-flex align-items-center justify-content-between mb-2">
                    <span *ngIf="formControlLength() > 1">Recipient {{ index + 1 }} </span>
                    <span *ngIf="formControlLength() > 1" (click)="removeSendBills(index)">
                      <img src="assets/icons/close.svg" />
                    </span>
                  </div>
                  <div [formGroupName]="index">
                    <div class="form-group">
                      <label>First name*</label>

                      <input
                        formControlName="firstName"
                        [ngClass]="validateSendBills('firstName', index)"
                        type="text"
                        class="form-control"
                        placeholder="First name"
                        [readonly]="readOnly"
                      />

                      <div *ngIf="submitted && validateSendBills('firstName', index)" class="invalid-feedback">
                        <div *ngIf="validateSendBills('firstName', index)">
                          First Name Is Required
                        </div>
                      </div>
                    </div>
                    <div class="form-group">
                      <label>Last name*</label>

                      <input
                        formControlName="lastName"
                        [ngClass]="validateSendBills('lastName', index)"
                        type="text"
                        class="form-control"
                        placeholder="Last name"
                        [readonly]="readOnly"
                      />

                      <div *ngIf="submitted && validateSendBills('lastName', index)" class="invalid-feedback">
                        <div *ngIf="validateSendBills('lastName', index)">
                          Last Name Is Required
                        </div>
                      </div>
                    </div>
                    <div class="form-group">
                      <label>Email*</label>

                      <input
                        formControlName="email"
                        [ngClass]="validateSendBills('email', index)"
                        type="text"
                        class="form-control"
                        placeholder="Email"
                        [readonly]="readOnly"
                      />

                      <div *ngIf="submitted && validateSendBills('email', index)" class="invalid-feedback">
                        <div *ngIf="sendBillsFormArrayControl[index].get('email').errors?.required">
                          Email Is Required
                        </div>
                        <div *ngIf="sendBillsFormArrayControl[index].get('email').errors?.email">
                          Invalid Email
                        </div>
                      </div>
                    </div>
                    <input type="hidden" formControlName="sendBillId" />
                  </div>
                </div>
              </div>

              <h4 *ngIf="!readOnly" class="font-cabin">
                <button
                  type="button"
                  (click)="addSendBills()"
                  class="btn btn-link p-0 text-capitalize h-auto font-cabin d-flex align-items-center font-size-18"
                >
                  <img width="14px" src="assets/icons/plus.svg" class="m-r-10" />
                  Add another recipient
                </button>
              </h4>

              <!-- TAX SETTINGS -->
              <div formGroupName="taxSettings">
                <h3
                  class="title mt-5 m-b-15"
                  [class.m-b-15]="vatDisabled"
                  [class.m-b-35]="!vatDisabled"
                >
                  Tax Settings
                </h3>
                <p *ngIf="vatDisabled" class="title-small">
                  If you wish to set your tax information, under "Account" settings, please set your account type to
                  "Commercial".
                </p>
                <h4 class="font-cabin text-primary d-flex align-items-center m-b-35 text-break">
                  Eligible for VAT*
                  <div class="custom-switch ml-auto">
                    <input
                      formControlName="isSalesTaxDeductionEligible"
                      class="tgl tgl-light tgl-primary"
                      id="cb2"
                      type="checkbox"
                      checked="false"
                    />
                    <label class="tgl-btn m-0" appToggleLabel for="cb2"></label>
                  </div>
                </h4>
                <div class="form-group">
                  <label>Vat ID</label>
                  <input formControlName="vatId" type="text" class="form-control" placeholder="Vat ID" />
                </div>
                <div class="form-group">
                  <label>Vat Type</label>
                  <ng-select
                    formControlName="vatType"
                    [items]="vatTypeOptionsData"
                    bindValue="name"
                    bindLabel="name"
                    placeholder="Select"
                    [readonly]="!isAdminEditing"
                    class="expanding-dropdown-panel"
                    appendTo="body"
                  >
                  </ng-select>
                </div>
              </div>
            </div>

            <div class="col-lg-4">
              <!-- Billing Address -->
              <div formGroupName="billingAddress">
                <h3 class="title">Billing Address</h3>
                <div class="form-group">
                  <label>Street and Number*</label>
                  <input
                    formControlName="streetNameAndNumber"
                    type="text"
                    class="form-control"
                    [class.is-invalid]="submitted && addressGroupControl('streetNameAndNumber').errors"
                    placeholder="Street and Number"
                  />

                  <div *ngIf="submitted && addressGroupControl('streetNameAndNumber').errors" class="invalid-feedback">
                    <div *ngIf="addressGroupControl('streetNameAndNumber').errors">
                      Street name and Number Is Required
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <label>Building Name and Number</label>
                  <input
                    formControlName="buildingNameAndNumber"
                    type="text"
                    class="form-control"
                    placeholder="Building Name and Number"
                  />
                </div>
                <div class="form-group">
                  <label>ZIP Code*</label>
                  <input
                    formControlName="zipCode"
                    type="text"
                    class="form-control"
                    placeholder="ZIP Code"
                    [class.is-invalid]="addressGroupControl('zipCode').errors"
                  />

                  <div *ngIf="submitted && addressGroupControl('zipCode').errors" class="invalid-feedback">
                    <div *ngIf="addressGroupControl('zipCode').errors">
                      ZIP Code Is Required
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <label>City*</label>
                  <input
                    [class.is-invalid]="addressGroupControl('billingCity').errors"
                    formControlName="billingCity"
                    type="text"
                    class="form-control"
                    placeholder="City"
                  />
                  <div *ngIf="submitted && addressGroupControl('billingCity').errors" class="invalid-feedback">
                    <div *ngIf="addressGroupControl('billingCity').errors">
                      City Is Required
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <label>Country*</label>
                  <ng-select
                    [class.is-invalid]="addressGroupControl('billingCountry').errors"
                    formControlName="billingCountry"
                    [items]="countryOptionsData"
                    bindValue="code"
                    bindLabel="name"
                    placeholder="Select"
                    class="expanding-dropdown-panel"
                    appendTo="body"
                  >
                  </ng-select>
                  <div *ngIf="submitted && addressGroupControl('billingCountry').errors" class="invalid-feedback">
                    <div *ngIf="addressGroupControl('billingCountry').errors">
                      Country Is Required
                    </div>
                  </div>
                </div>
                <input type="hidden" formControlName="billingAddressId" />
              </div>

              <!-- Billing Settings -->
              <ng-template ngxPermissionsExcept="Publisher">
                <div formGroupName="billingSettings">
                  <h3 class="title mt-5 mb-3">Billing Settings</h3>
                  <div class="form-group">
                    <label>Default due date</label>
                    <div class="input-group">
                      <input
                        type="number"
                        [attr.disabled]="isAdminEditing ? null : 'true'"
                        formControlName="defaultDueDate"
                        class="form-control"
                        placeholder="Default due date"
                      />
                      <div class="input-group-append">
                        <span class="input-group-text">Days after billing</span>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-template>
            </div>
            <div class="col-lg-4">
              <!-- Payout -->
              <ng-template ngxPermissionsOnly="Publisher">
                <div formGroupName="payout">
                  <h3 class="title">Payout</h3>
                  <div class="form-group">
                    <label>Payout method</label>
                    <ng-select
                      formControlName="payoutMethod"
                      [items]="payoutMethodOptionsData"
                      bindValue="id"
                      bindLabel="name"
                      placeholder="Select"
                      [readonly]="true"
                      class="expanding-dropdown-panel"
                      appendTo="body"
                    >
                    </ng-select>
                  </div>
                  <div class="form-group">
                    <label>Payout threshold</label>
                    <div class="input-group">
                      <input
                        formControlName="payoutThreshold"
                        type="number"
                        class="form-control"
                        [class.is-invalid]="this.financeSettingForm.get('payout').get('payoutThreshold').errors"
                      />
                      <div class="input-group-append">
                        <span class="input-group-text">EUR</span>
                      </div>
                    </div>
                    <div class="invalid-feedback text-capitalize"
                         *ngIf="submitted && this.financeSettingForm.get('payout').get('payoutThreshold').errors">
                      <div>
                        Payment threshold must be at least
                        {{ minimumPayoutThreshold }}
                        EUR
                      </div>
                    </div>
                    <p class="title-small mt-1">
                      The amount you set here will determine the amount that is needed for us to trigger your payout.
                    </p>
                    <p class="title-small mt-1">
                      As your bank account type is
                      <span *ngIf="this.bankingGroupControl('bankType').value === BankType.SEPA">
                        SEPA,
                      </span>
                      <span *ngIf="this.bankingGroupControl('bankType').value === BankType.INTERNATIONAL">
                        International,
                      </span>
                      the minimum payment threshold is
                      <span>{{ minimumPayoutThreshold | humanize: 'number' }}</span>
                      EUR
                   </p>
                  </div>
                </div>
              </ng-template>

              <!-- Banking -->
              <ng-template ngxPermissionsExcept="Advertiser">
                <div formGroupName="banking">
                  <h3 class="title mt-5 mb-3">
                    Banking
                  </h3>

                  <div class="form-group">
                    <label>Account Owner Name*</label>

                    <input
                      formControlName="accountName"
                      type="text"
                      class="form-control"
                      placeholder="Account Owner Name"
                      [class.is-invalid]="bankingGroupControl('accountName').errors"
                    />

                    <div class="invalid-feedback text-capitalize"
                      *ngIf="submitted && bankingGroupControl('accountName').errors">
                      <div>
                        Account Owner Name is required
                      </div>
                    </div>

                    <p class="title-small mt-1">
                      The owner name stated on your bank account. It can be a company or person.
                    </p>
                  </div>

                  <div class="form-group">
                    <label>Bank Type</label>

                    <ng-select
                      formControlName="bankType"
                      bindLabel="name"
                      bindValue="value"
                      [items]="bankTypes"
                    >
                    </ng-select>
                  </div>

                  <div class="form-group" *ngIf="isInternationalSelected()">
                    <label>Account Number*</label>

                    <input
                      formControlName="accountNo"
                      type="text"
                      class="form-control"
                      placeholder="Account Number"
                      [class.is-invalid]="bankingGroupControl('accountNo').errors"
                    />

                    <div class="invalid-feedback text-capitalize"
                      *ngIf="submitted && bankingGroupControl('accountNo').errors">
                      <div>
                        Account Number is required
                      </div>
                    </div>
                  </div>

                  <div class="form-group text-uppercase" *ngIf="isSEPASelected()">
                    <label>Iban*</label>

                    <input
                      formControlName="iban"
                      type="text"
                      class="form-control text-uppercase"
                      placeholder="Iban"
                      [class.is-invalid]="bankingGroupControl('iban').errors"
                    />

                    <div class="invalid-feedback text-capitalize"
                      *ngIf="submitted && bankingGroupControl('iban').errors">
                      <div>
                        IBAN Is Required
                      </div>
                    </div>
                  </div>

                  <div class="form-group text-uppercase">
                    <label>Bic / Swift*</label>

                    <input
                      formControlName="bicSwift"
                      type="text"
                      class="form-control text-uppercase"
                      placeholder="Bic / Swift"
                      [class.is-invalid]="bankingGroupControl('bicSwift').errors"
                    />

                    <div class="invalid-feedback text-capitalize" *ngIf="submitted && bankingGroupControl('bicSwift').errors">
                      <div>
                        BIC / SWIFT Code is Required
                      </div>
                    </div>
                  </div>

                  <div class="form-group">
                    <label> Name of the Bank institute*</label>

                    <input
                      formControlName="creditInstitutionName"
                      type="text"
                      class="form-control"
                      placeholder="Name of the Bank institute"
                      [class.is-invalid]="bankingGroupControl('creditInstitutionName').errors"
                    />

                    <div *ngIf="submitted && bankingGroupControl('creditInstitutionName').errors" class="invalid-feedback text-capitalize">
                      <div *ngIf="bankingGroupControl('creditInstitutionName').errors">
                        Name of the Credit Institution Is Required
                      </div>
                    </div>
                  </div>

                  <h4 class="title mt-2 mb-2">
                    Bank Address
                  </h4>

                  <p class="title-small" *ngIf="isInternationalSelected()">
                    We require <b>your bank's address</b>, not your company's address, in order to perform a bank
                    transfer.
                  </p>

                  <div *ngIf="isInternationalSelected()">
                    <label>Bank Street and Number</label>

                    <div class="form-row">
                      <div class="form-group col-md-7">
                        <input
                          [class.is-invalid]="bankingGroupControl('street').errors"
                          formControlName="street"
                          type="text"
                          class="form-control"
                          placeholder="Street"
                        />

                        <div *ngIf="submitted && bankingGroupControl('street').errors" class="invalid-feedback text-capitalize">
                          <div *ngIf="bankingGroupControl('street').errors">
                            Street Is Required
                          </div>
                        </div>
                      </div>

                      <div class="form-group col-md-5">
                        <input
                          [class.is-invalid]="bankingGroupControl('streetNumber').errors"
                          formControlName="streetNumber"
                          type="text"
                          class="form-control"
                          placeholder="Number"
                        />

                        <div *ngIf="submitted && bankingGroupControl('streetNumber').errors" class="invalid-feedback">
                          <div *ngIf="bankingGroupControl('streetNumber').errors">
                            Number Is Required
                          </div>
                        </div>
                      </div>
                    </div>

                    <p class="title-small">
                      The bank's street and number, not your company!
                    </p>
                  </div>

                  <div class="form-group">
                    <label>Bank City*</label>

                    <input
                      [class.is-invalid]="bankingGroupControl('bankingCity').errors"
                      formControlName="bankingCity"
                      type="text"
                      class="form-control"
                      placeholder="City"
                    />

                    <div *ngIf="submitted && bankingGroupControl('bankingCity').errors" class="invalid-feedback">
                      <div *ngIf="bankingGroupControl('bankingCity').errors">
                        City Is Required
                      </div>
                    </div>

                    <p class="title-small mt-1">
                      The bank's city, not your company!
                    </p>
                  </div>


                  <div class="form-group">
                    <label>Bank Country*</label>

                    <ng-select
                      id="bankCountry"
                      formControlName="bankingCountry"
                      [items]="countryOptionsData"
                      bindValue="code"
                      bindLabel="name"
                      placeholder="Select"
                      class="expanding-dropdown-panel"
                      [class.is-invalid]="bankingGroupControl('bankingCountry').errors"
                      appendTo="#bankCountry"
                    >
                    </ng-select>

                    <div *ngIf="submitted && bankingGroupControl('bankingCountry').errors" class="invalid-feedback">
                      <div *ngIf="bankingGroupControl('bankingCountry').errors">
                        Country Is Required
                      </div>
                    </div>

                    <p class="title-small mt-1">
                      The bank's country, not your company!
                    </p>
                  </div>

                  <div class="form-group" *ngIf="isInternationalSelected()">
                    <label>Bank ZIP Code*</label>

                    <input
                      [class.is-invalid]="bankingGroupControl('bankingZip').errors"
                      formControlName="bankingZip"
                      type="text"
                      class="form-control"
                      placeholder="ZIP Code"
                    />

                    <div *ngIf="submitted && bankingGroupControl('bankingZip').errors" class="invalid-feedback">
                      <div *ngIf="bankingGroupControl('bankingZip').errors">
                        Country Is Required
                      </div>
                    </div>

                    <p class="title-small mt-1">
                      The bank's zip code, not your company!
                    </p>
                  </div>

                  <input type="hidden" formControlName="bankingId" />
                  <input type="hidden" formControlName="bankingAddressId" />
                </div>
              </ng-template>

              <!-- Fees -->
              <ng-template ngxPermissionsExcept="Publisher">
                <div formGroupName="fees">
                  <h3 class="title mt-5 mb-3">
                    Fees
                  </h3>

                  <div class="form-group">
                    <label>Split</label>
                    <div class="input-group">
                      <input
                        formControlName="split"
                        [attr.disabled]="isAdminEditing ? null : 'true'"
                        type="number"
                        class="form-control"
                        placeholder="Split"
                      />
                      <div class="input-group-append">
                        <span class="input-group-text">%</span>
                      </div>
                    </div>
                  </div>
                  <div class="form-row">
                    <div class="form-group col-md-7">
                      <label>Recurring Fees</label>
                      <div class="input-group">
                        <input
                          formControlName="recurringFees"
                          [attr.disabled]="isAdminEditing ? null : 'true'"
                          type="number"
                          class="form-control"
                          placeholder="Recurring Fees"
                        />
                        <div class="input-group-append">
                          <span class="input-group-text text-uppercase">Eur</span>
                        </div>
                      </div>
                    </div>
                    <div class="form-group col-md-5">
                      <label>Interval</label>
                      <input [attr.disabled]="true" class="form-control" value="Monthly" />
                    </div>
                  </div>
                </div>
              </ng-template>
            </div>
          </div>
        </div>

        <div *ngIf="!readOnly" class="card-footer text-right">
          <button type="submit" class="btn btn-primary">
            Save
          </button>
        </div>
      </div>

      <input type="hidden" formControlName="accountId" />
    </form>
  </div>
</div>
