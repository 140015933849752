import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

import { PasswordValidator } from 'src/app/shared/constants/auth';
import { MustMatch } from 'src/app/shared/helpers/must-match.validator';
import { BreadcrumbService } from 'src/app/shared/services/breadcrumb.service';
import { CoreService } from 'src/app/shared/services/core.service';
import { IndicatorService } from 'src/app/shared/services/indicator.service';
import { SettingsService } from 'src/app/shared/services/settings.service';
import { TitleService } from 'src/app/shared/services/title-service';

@Component({
  selector: 'app-password-settings',
  templateUrl: './password-settings.component.html',
  styleUrls: ['./password-settings.component.scss'],
})
export class PasswordSettingsComponent implements OnInit {
  otherUsers = false;

  breadCrumbData = [
    { name: 'Settings', link: '/setting/account' },
    { name: 'Password Settings', link: '/setting/Password-settings' },
  ];

  currentPassword = new UntypedFormControl('', [Validators.required]);

  newPassword = new UntypedFormControl('', [Validators.required]);

  confirmNewPassword = new UntypedFormControl('', [Validators.required]);
  passwordForm: UntypedFormGroup;
  submitted = false;

  constructor(
    public breadcrumbService: BreadcrumbService,
    private coreService: CoreService,
    private settingsService: SettingsService,
    private formBuilder: UntypedFormBuilder,
    private indicatorService: IndicatorService,
    private titleService: TitleService,
  ) {
    if (this.coreService.settingsLocation === 'Account-Admin') {
      this.otherUsers = true;

      this.passwordForm = this.formBuilder.group({
        newPassword: ['', PasswordValidator.rules],
        confirmNewPassword: ['', Validators.required],
        currentPassword: [''],
      }, {
        validator: MustMatch('newPassword', 'confirmNewPassword'),
      });
    } else {
      this.passwordForm = this.formBuilder.group({
        confirmNewPassword: ['', Validators.required],
        currentPassword: ['', [Validators.required]],
        newPassword: ['', [Validators.required].concat(PasswordValidator.rules)],
      }, {
        validator: MustMatch('newPassword', 'confirmNewPassword'),
      });
    }

  }

  ngOnInit(): void {
    this.titleService.setTitle('Setting - Password Settings');
    this.breadcrumbService.updateBreadcrumbsData(
      this.breadCrumbData,
    );
  }

  get passwordFormControls() {
    return this.passwordForm.controls;
  }

  onSubmit() {
    this.submitted = true;

    if (this.passwordForm.invalid) {
      return;
    }

    if (this.otherUsers === true) {
      this.otherUser();
    } else {
      this.currentUser();
    }
  }

  currentUser() {
    const body = {
      new_password1: this.passwordForm.value.newPassword,
      new_password2: this.passwordForm.value.confirmNewPassword,
      old_password: this.passwordForm.value.currentPassword,
    };

    this.settingsService.changePassword(body).then(
      () => this.coreService.showSuccess('Password Changed'),
      (error: HttpErrorResponse) => {
        if (error.error.old_password) {
          this.coreService.showError('Invalid Old Password');
          return;
        }

        this.indicatorService.failed('Error', 'An Error Occurred, Try Again Later!');
      },
    );
  }

  otherUser() {
    const body = {
      new_password1: this.passwordForm.value.newPassword,
      new_password2: this.passwordForm.value.confirmNewPassword,
      user_id: this.coreService.editUserId,
    };

    this.settingsService.changeOtherUserPassword(body).then(
      () => this.indicatorService.success('Success', 'Password Changed Successfully'),
      () => this.indicatorService.failed('Error', 'Changing Password Failed, Try Again Later!'),
    );
  }
}
