import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

import {
  AdSpaceSettingsModelComponent,
} from 'src/app/modules/setting/ad-space-settings-model/ad-space-settings-model.component';
import { SettingSection } from 'src/app/modules/setting/settings.base';
import { IAdSpace } from 'src/app/shared/constants/adSpace';
import { AdSpaceService } from 'src/app/shared/services/ad-space.service';
import { BreadcrumbService } from 'src/app/shared/services/breadcrumb.service';
import { CoreService } from 'src/app/shared/services/core.service';
import { TitleService } from 'src/app/shared/services/title-service';

import { Subscription } from 'rxjs';

@Component({
  selector: 'app-ad-space-settings',
  templateUrl: './ad-space-settings.component.html',
  styleUrls: ['./ad-space-settings.component.scss'],
})
export class AdSpaceSettingsComponent
  extends SettingSection implements OnInit, OnDestroy {

  breadCrumbData = [
    { name: 'Settings', link: '/setting/account' },
    { name: 'AdSpace', link: '/setting/ad-space' },
  ];

  public modalReference: NgbModalRef;
  private getAdSpaceSubscription: Subscription;
  public addSpaceList: IAdSpace[] = [];
  public trafficDropdownList = [];
  private currentAccountId = null;
  public readOnly = false;

  constructor(public coreService: CoreService,
    public breadcrumbService: BreadcrumbService,
    private modalService: NgbModal,
    private adSpaceService: AdSpaceService,
    private titleService: TitleService) {
    super();

    this.currentAccountId = this.coreService.settingsLocation === 'Account-Admin' ?
      this.coreService.editUserAccountId :
      this.coreService.getCurrentAccountId();
  }

  ngOnInit(): void {
    this.titleService.setTitle('Setting - AdSpace Settings');
    this.breadcrumbService.updateBreadcrumbsData(this.breadCrumbData);
    this.getAdSpaceList();
    this.getTraffic();

    this.applyAccessScope();
  }

  async applyAccessScope() {
    await this.applyAccessScopeFlags();
  }

  ngOnDestroy(): void {
    this.unsubscribeObject(this.getAdSpaceSubscription);
  }

  openModal(adSpaceId?) {
    this.modalReference = this.modalService.open(AdSpaceSettingsModelComponent, {
      centered: true,
      scrollable: true,
    });

    (this.modalReference.componentInstance as AdSpaceSettingsModelComponent).adSpaceId = adSpaceId;

    this.modalReference.result.then(
      () => this.getAdSpaceList(),
      () => this.getAdSpaceList(),
    );
  }

  getAdSpaceList() {
    const params = {
      account: this.currentAccountId,
    };

    this.getAdSpaceSubscription = this.adSpaceService
      .getAdSpaceList(params)
      .subscribe((data) => this.addSpaceList = (data as IAdSpace[]));
  }

  unsubscribeObject(object: Subscription) {
    if (object) {
      object.unsubscribe();
    }
  }

  getTraffic() {
    this.adSpaceService.getAdspaceTraffic().then((data: any) => {
      this.trafficDropdownList = data;
    });
  }
}
